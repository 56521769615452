/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import PropTypes from 'prop-types';
import companyApi from '@services/company';
import STATUSCODE from '@common/constant/status';
import { t } from 'i18next';
import layoutApi from '@services/layout';
import { PATH_URL } from '@common/config/pathURL';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/lib/locale/ja_JP';
import { ConfigProvider, Table } from 'antd';
import { IconEditBig, IconEditSmall } from '@assets/icons';
import { useSelector } from 'react-redux';
import { timeDateDisplay } from '@common/utils/dateHelper';
import customTooltip from '@common/utils/customTooltip';
import { LayoutContext } from '..';

function LayoutList({ showModal, setIsAddForm, company_id, dataSearch }) {
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    company_name: '',
    company_ids: Number(company_id),
    order_by: 'id',
    keyword: dataSearch?.layoutKeyword,
  });
  const Navigate = useNavigate();
  const [listCompany, setListCommpany] = useState();
  const [optionFilter, setOptionFilter] = useState([]);
  const { loading, showDataLayout } = useContext(LayoutContext);
  const stateLayout = useSelector(
    (state) => state?.layouts?.layoutList?.data?.data,
  );
  const { loadingTable, value } = useFetch(
    () => layoutApi.getListLayout(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.room_name,
      pagination.company_ids,
      pagination.page_size,
      pagination.company_name,
      loading,
      company_id,
      stateLayout,
      pagination.order_by,
      pagination.keyword,
    ],
  );
  const totalPage = value?.data?.count;
  const checkColumnSorted = pagination?.order;

  const hanldeChangeTable = (_, filter, sorter) => {
    const sortField = sorter?.field;
    const checkSortField = {
      company: 'company_name',
    };
    if (sorter?.order === 'descend') {
      setPagination({
        ...pagination,
        order: 'DESC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    } else {
      setPagination({
        ...pagination,
        order: 'ASC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    }
  };
  const getDetailLayout = async (values) => {
    setIsAddForm(false);
    try {
      const res = await layoutApi.getDetailLayout(values?.id);
      const statusRes = res?.statusCode;
      if (statusRes === STATUSCODE.SUCCESS) {
        showDataLayout({
          ...res?.data,
        });
        showModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListCompany = async () => {
    try {
      const res = await companyApi.getListCompany();
      setListCommpany(res);
      const companyData = res.data.data.map((company) => ({
        id: company?.id,
        name: company?.name,
      }));
      const uniqueObjects = companyData.filter((data, index, self) => {
        const currentName = data.name;
        return self.findIndex((obj) => obj.name === currentName) === index;
      });
      const childrenCompanyData = uniqueObjects.map((company) => ({
        label: company.name,
        value: company.id,
        title: company.name,
        key: company.id,
      }));
      setOptionFilter((prevOptionFilter) => [
        ...prevOptionFilter,
        {
          title: '会社名',
          value: '0',
          key: '0',
          children: childrenCompanyData,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchListCompany();
  }, []);

  const columns = [
    {
      title: t('layout.table.layoutName'),
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 400,
      key: 'name',
      render: (values) => {
        return <span className="pointer">{values}</span>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: t('layout.table.numberFab'),
      dataIndex: 'fab_count',
      key: 'fab_count',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 150,
      render: (values, record) => {
        return (
          <div className="display-flex jc-space-btwn">
            <div>{values}</div>
            <IconEditSmall
              className="pointer"
              onClick={() =>
                Navigate(PATH_URL.MANAGER_FABS.INDEX, {
                  state: {
                    layout_id: record?.id,
                    layout_name: record?.name,
                  },
                })
              }
            />
          </div>
        );
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'fab_count')}
          </div>
        ),
      },
    },

    {
      title: t('layout.table.createDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return <div>{timeDateDisplay(values)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 88,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            getDetailLayout(record);
          },
        };
      },
    },
  ];

  return (
    <div className="department-list-wrapper">
      <ConfigProvider locale={locale}>
        <div className="card-wrapper-department">
          <Table
            className="table-custom-layout"
            rowKey={(d) => d.id}
            showHeader
            columns={columns}
            dataSource={value?.data?.data}
            onChange={hanldeChangeTable}
            showModal={showModal}
            setIsAddForm={setIsAddForm}
            pagination={false}
            loading={loadingTable}
          />
        </div>
      </ConfigProvider>
    </div>
  );
}
LayoutList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
  company_id: PropTypes.number,
  dataSearch: PropTypes.object,
};

export default LayoutList;
