import api from './baseApi';

const layoutApi = {
  getListLayout: (params) => {
    return api.get('/v1/manage-admin/layout', { params });
  },
  getDetailLayout: (id) => {
    return api.get(`/v1/manage-admin/layout/${id}`);
  },
  postLayout: (params) => {
    return api.post('/v1/manage-admin/layout', params);
  },
  editlayout: (id, params) => {
    return api.put(`/v1/manage-admin/layout/${id}`, params);
  },
  deleteLayout: (id) => {
    return api.delete(`/v1/manage-admin/layout/${id}`);
  },
};

export default layoutApi;
