import api from './baseApi';

const departmentApi = {
  getListDepartment: (params) => {
    return api.get('/v1/manage-admin/department', { params });
  },
  getListDropdown: (id) => {
    return api.get(`/v1/manage-admin/department/dropdown/company/${id}`);
  },
  getDetailDepartment: (id) => {
    return api.get(`/v1/manage-admin/department/${id}`);
  },
  postDepartment: (params) => {
    return api.post('/v1/manage-admin/department', params);
  },
  editDepartment: (id, params) => {
    return api.put(`/v1/manage-admin/department/${id}`, params);
  },
  deleteDepartment: (id) => {
    return api.delete(`/v1/manage-admin/department/${id}`);
  },
};

export default departmentApi;
