import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import auth from './slices/authSlice';
import project from './slices/projectSlice';
import company from './slices/companySlice';
import layout from './slices/layoutSlice';
import layouts from './slices/layoutSlices';

const reducers = combineReducers({
  auth,
  project,
  layout,
  company,
  layouts,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // only auth will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});
