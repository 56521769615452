/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import ComTable from '@components/common/ComTable';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import companyApi from '@services/company';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { timeDateDisplay } from '@common/utils/dateHelper';
import customTooltip from '@common/utils/customTooltip';

function CompanyCustomList({ showModal, setIsAddForm }) {
  const location = useLocation();
  const stateCompay = useSelector(
    (state) => state?.company?.companyList?.data?.data,
  );
  const stateLayout = useSelector(
    (state) => state?.layouts?.layoutList?.data?.data,
  );
  const [optionFilter, setOptionFilter] = useState([]);
  const [company, setListCompany] = useState();

  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    layoutKeyword: '',
    company_ids: location?.state?.company_id,
    type: '',
    order_by: 'id',
  });
  const { loadingTable, value } = useFetch(
    () => companyApi.getListCompany(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.name,
      pagination.company,
      pagination.page_size,
      pagination.company,
      pagination.order_by,
      pagination.layoutKeyword,
      pagination.company_ids,
      stateCompay,
      stateLayout,
    ],
  );
  const checkColumnSorted = pagination?.order;
  const paginationProp = {
    position: ['bottomRight'],
    total: value?.data?.count,
    pageSize: pagination?.page_size,
    currentPage: pagination?.page_number,
  };

  const handleSearch = (keyWord) => {
    setPagination({
      ...pagination,
      layoutKeyword: keyWord?.trim(),
      page_number: 1,
    });
  };

  const onChangeSelect = (companyData) => {
    setPagination({ ...pagination, company_ids: companyData });
  };

  const handleChangePagination = (currentPage, pageSize) => {
    setPagination((curState) => ({
      ...curState,
      page_size: pageSize,
      page_number: currentPage,
    }));
  };
  const hanldeChangeTable = (_, filter, sorter) => {
    if (sorter?.order === 'descend') {
      setPagination({ ...pagination, order: 'DESC', order_by: sorter?.field });
    } else {
      setPagination({ ...pagination, order: 'ASC', order_by: sorter?.field });
    }
  };
  const onChangeTreeSelect = (dataSelect) => {
    const filterOption = company?.filter((item) =>
      dataSelect.includes(item?.id),
    );
    const paramType = filterOption.map((x) => x.id).join(',');
    setPagination({ ...pagination, company_ids: paramType, page_number: 1 });
  };
  const fetchListCompany = async () => {
    try {
      const res = await companyApi.getListCompany();
      setListCompany(res?.data?.data);
      const dataCompany = res?.data?.data?.map((data) => ({
        id: data?.id,
        name: data?.name,
      }));
      const uniqueObjects = dataCompany.filter((data, index, self) => {
        const currentName = data.name;
        return self.findIndex((obj) => obj.name === currentName) === index;
      });
      const childrenCompanyData = uniqueObjects.map((data) => ({
        label: data.name,
        value: data.id,
        title: data.name,
      }));
      setOptionFilter((prevOptionFilter) => [
        ...prevOptionFilter,
        {
          title: '会社名',
          value: '0',
          key: '0',
          children: childrenCompanyData,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  const filterTreeNode = (inputValue, treeNode) => {
    const title = treeNode?.props?.title.trim() || '';
    return title.toLowerCase().includes(inputValue.toLowerCase().trim());
  };
  useEffect(() => {
    fetchListCompany();
  }, []);
  useEffect(() => {
    handleSearch();
  }, []);

  const columns = [
    {
      title: t('dashboard.tab2.form.companyName'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 447,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: 'レイアウト数',
      key: 'layout_count',
      sorter: true,
      dataIndex: 'layout_count',
      width: 250,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'layout_count')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.dateCreate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 250,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return <div>{timeDateDisplay(values)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
  ];

  return (
    <div className="layout-list-wrapper">
      <ComTable
        title="レイアウト"
        loading={loadingTable}
        columns={columns}
        data={value?.data?.data}
        pagination={paginationProp}
        onSearch={handleSearch}
        dataSearch={pagination}
        onChangeSelect={onChangeSelect}
        onChangeTable={hanldeChangeTable}
        onChangePagination={handleChangePagination}
        showModal={showModal}
        setIsAddForm={setIsAddForm}
        chilDren="layout"
        onChangeTreeSelect={onChangeTreeSelect}
        optionsTreeSelect={optionFilter}
        treeNodeFilterProp="title"
        filterTreeNode={filterTreeNode}
        isTreeSelect
      />
    </div>
  );
}

CompanyCustomList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
};

export default CompanyCustomList;
