/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import { Button, Form, message, Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const beforeUpload = (file, t) => {
  const isJpgOrPng = ['image/jpeg', 'image/png'].includes(file.type);

  if (!isJpgOrPng) {
    message.error('画像のフォーマットは正しくないです。');
  }

  const isLt1M = file.size / 1024 / 1024 < 1;

  if (!isLt1M) {
    message.error('画像のサイズを1MB未満にしてください。');
  }

  return isJpgOrPng && isLt1M;
};

export default function ComUpload({
  length = 1,
  name,
  className = '',
  label,
  fileList,
  setFileList,
  rules,
  disabled,
  isFile,
}) {
  const { t } = useTranslation();
  const uploadRef = useRef();

  const normFile = (e, v) => {
    const isValid =
      e.file.status === 'removed' ? true : beforeUpload(e.file, t);
    if (Array.isArray(e)) {
      return e;
    }
    if (isValid) {
      setFileList(e?.fileList);
      return e?.fileList;
    }
    return fileList;
  };
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 10 },
      xl: { span: 7 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  return (
    <div className="position-relative">
      <Form.Item
        {...configTextField}
        name={name}
        initialValue={fileList}
        valuePropName="fileList"
        label={label}
        getValueFromEvent={normFile}
        rules={rules}
        disabled={disabled}
      >
        <Upload
          ref={uploadRef}
          beforeUpload={() => false}
          name={`child_${name}`}
          className={`upload ${className}`}
          listType="picture-card"
          maxCount={1}
          onPreview={() => {}}
        >
          {fileList.length < length && (
            <div className="text-upload">
              未選択
              <br /> (デフォルト)
            </div>
          )}
        </Upload>
      </Form.Item>
    </div>
  );
}
ComUpload.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  length: PropTypes.number,
  label: PropTypes.string,
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
  rules: PropTypes.array,
  disabled: PropTypes.bool,
  isFile: PropTypes.bool,
};
