// eslint-disable-next-line no-unneeded-ternary
import React, { useEffect, useState } from 'react';
import authApi from '@services/auth';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { Dropdown, Layout, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_URL } from '@common/config/pathURL';
import userApi from '@services/userApi';
import { t } from 'i18next';
import { useMsal } from '@azure/msal-react';
import logo from '../../assets/imgs/FaView - Logo.png';

function Header() {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const saveAvatar = useSelector((state) => state?.auth?.avatar);
  const [dataAccount, setDataAccount] = useState();
  const getDataAccount = async () => {
    const data = await userApi.getMe();
    setDataAccount(data?.data);
  };
  const handleLogout = async () => {
    try {
      await authApi.logout();
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
      dispatch(logoutSuccess());
    } catch (err) {
      message.error('Error');
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <a href={PATH_URL.ACCOUNT.INDEX} rel="noreferrer">
          {t('login.btnUser')}
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <div className="full-height full-width" onClick={() => handleLogout()}>
          {t('login.btnLogout')}
        </div>
      ),
    },
  ];
  useEffect(() => {
    getDataAccount();
  }, []);

  return (
    <Layout.Header className="layout-header">
      <Row
        align="middle"
        justify="space-between"
        className="row-top-header pr-20 pl-20"
      >
        <img src={logo} alt="" />
        <Dropdown
          className="button-logout pointer"
          menu={{ items }}
          arrow={{ pointAtCenter: true }}
        >
          <img src={saveAvatar || dataAccount?.avatar} alt="" />
        </Dropdown>
      </Row>
    </Layout.Header>
  );
}

Header.propTypes = {};

export default Header;
