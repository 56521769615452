function customTooltip(pagination, checkColumnSorted, name) {
  if (pagination.order_by === name) {
    if (checkColumnSorted === 'DESC') {
      return 'クリックで昇順にソート';
      // eslint-disable-next-line no-else-return
    } else if (checkColumnSorted === 'ASC') {
      return 'クリックで降順にソート';
    }
  }
  return 'クリックで昇順にソート';
}
export default customTooltip;
