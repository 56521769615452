import api from './baseApi';

const objectApi = {
  getListObjectTemplate: (params) => {
    return api.get('/v1/manage-admin/object-template', { params });
  },
  getListObjectType: () => {
    return api.get('/v1/manage-admin/object-type');
  },
  getDetailObjectTemplate: (id) => {
    return api.get(`/v1/manage-admin/object-template/${id}`);
  },
  postObjectTemplate: (params) => {
    return api.post('/v1/manage-admin/object-template', params);
  },
  editObjectTemplate: (id, params) => {
    return api.put(`/v1/manage-admin/object-template/${id}`, params);
  },
  deleteObjectTemplate: (id) => {
    return api.delete(`/v1/manage-admin/object-template/${id}`);
  },
};

export default objectApi;
