/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import {
  IconAdd,
  IconDropdown2,
  IconExportExcel,
  IconFilter,
  IconOpen,
} from '@assets/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  Select,
  Table,
  TreeSelect,
} from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import PropTypes from 'prop-types';
import ManageDepartment from '@pages/manager/Department';
import ManageLayout from '@pages/manager/Layout';
import userApi from '@services/userApi';
import ComLoading from './ComLoading';

const { Search } = Input;

function ComTable({
  pagination,
  title,
  tableLayout,
  onChangePagination,
  onChangeTable,
  columns = [],
  data = [],
  onClickRow = () => false,
  onAddTable,
  onSearch,
  hasButtonAdd,
  loading,
  valueTreeSelect,
  onChangeTreeSelect,
  isTreeSelect,
  optionsTreeSelect,
  optionSelect,
  valueSelect,
  onChangeSelect,
  rowClassName,
  isSelect,
  treeNodeFilterProp,
  filterTreeNode,
  chilDren,
  buttonExport,
  dataSearch,
  dataExport,
  showModal = () => false,
  setIsAddForm = () => false,
}) {
  const inputRef = useRef();
  const childElements = inputRef.current?.querySelectorAll(
    '.ant-select-selection-search-input',
  );
  childElements?.forEach((element) => {
    element.disabled = true;
  });

  const [expandedRowKey, setExpandedRowKey] = useState();
  const openModalAdd = () => {
    setIsAddForm(true);
    showModal();
  };
  const tableLoading = {
    spinning: loading,
    indicator: <ComLoading type="loading" />,
  };

  const expandIcon = ({ expanded, onExpand, record }) => (
    <span
      className="display-flex jc-center"
      onClick={(e) => onExpand(record, e)}
    >
      {expanded ? (
        <IconDropdown2 className="pointer" />
      ) : (
        <IconOpen className="pointer" />
      )}
    </span>
  );
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKey(keys);
  };
  const checkChildren = {
    department: <ManageDepartment id={expandedRowKey} />,
    layout: <ManageLayout id={expandedRowKey} dataSearch={dataSearch} />,
  };
  const { pageSize, currentPage, total } = pagination;

  const exportFileExcel = async () => {
    try {
      const fileData = await userApi.exportExcel(dataExport);
      const blob = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'users.xlsx');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <div className="card-wrapper pt-45">
        <Card
          extra={
            <>
              <div className="title-header item-center display-flex jc-space-btwn ">
                <span>
                  <span className="title">{title}</span>
                </span>
                <div className="display-flex">
                  {buttonExport && (
                    <span className="display-flex jc-center pointer pr-20">
                      <IconExportExcel onClick={() => exportFileExcel()} />
                    </span>
                  )}
                  <span className="display-flex jc-center pointer">
                    <IconAdd onClick={() => openModalAdd()} />
                  </span>
                </div>
              </div>
              <Col sm={24} className="display-flex jc-start extra-card">
                <div className="display-flex item-center extra-search ">
                  <Search
                    allowClear
                    onSearch={onSearch}
                    className="table-input-search"
                  />
                </div>

                {isTreeSelect && (
                  <div className="display-flex item-center extra-tree-select jc-end">
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      maxTagCount={2}
                      treeCheckable
                      suffixIcon={
                        <IconFilter
                          className="pointer"
                          width={17}
                          height={17}
                        />
                      }
                      className="table-input-search"
                      value={valueTreeSelect}
                      treeData={optionsTreeSelect}
                      treeDefaultExpandAll
                      onChange={onChangeTreeSelect}
                      filterTreeNode={filterTreeNode}
                      treeNodeFilterProp={treeNodeFilterProp}
                      allowClear
                    >
                      {/* {optionsTreeSelect?.map((node) => (
                        <TreeNode key={node?.key} title={node?.title}>
                          {node.children &&
                            node?.children.map((child) => (
                              <TreeNode key={child?.key} title={child?.title} />
                            ))}
                        </TreeNode>
                      ))} */}
                    </TreeSelect>
                  </div>
                )}

                {isSelect && (
                  <div className="display-flex item-center  extra-select jc-end">
                    <Select
                      showSearch
                      allowClear
                      suffixIcon={
                        <IconFilter
                          className="pointer"
                          width={17}
                          height={17}
                        />
                      }
                      optionFilterProp="children"
                      onChange={onChangeSelect}
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelect}
                      value={valueSelect}
                    />
                  </div>
                )}

                {hasButtonAdd && (
                  <Button onClick={onAddTable}>
                    <PlusOutlined />
                  </Button>
                )}
              </Col>
            </>
          }
        >
          <div>
            <Table
              className="table-custom"
              showHeader
              showfooter
              onChange={onChangeTable}
              tableLayout={tableLayout}
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
              loading={tableLoading}
              showSorterTooltip={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => onClickRow(record, rowIndex, e),
                };
              }}
              rowKey={(d) => d.id}
              rowClassName={rowClassName}
              expandable={
                chilDren && {
                  expandedRowRender: () => checkChildren[chilDren],
                  expandIcon,
                  onExpand: onTableRowExpand,
                  expandedRowKeys: expandedRowKey,
                  columnTitle:
                    chilDren === 'department' ? (
                      <div className="display-flex jc-center word-break">
                        部署
                      </div>
                    ) : (
                      <div className="display-flex jc-center fz-8">
                        レイアウト
                      </div>
                    ),
                }
              }
            />
          </div>

          <div className="pagination-wrapper full-width pr-10" ref={inputRef}>
            <Pagination
              className="pagination"
              current={currentPage}
              pageSizeOptions={[15, 30, 50, 100]}
              pageSize={pageSize}
              showSizeChanger
              total={total}
              onChange={onChangePagination}
              hideOnSinglePage={false}
            />
          </div>
        </Card>
      </div>
    </ConfigProvider>
  );
}

ComTable.propTypes = {
  rowClassName: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  tableLayout: PropTypes.oneOf(['auto', 'fixed']),
  onChangePagination: PropTypes.func,
  onChangeTable: PropTypes.func,
  onAddTable: PropTypes.func,
  onSearch: PropTypes.func,
  onClickRow: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  isTreeSelect: PropTypes.bool,
  optionsTreeSelect: PropTypes.array,
  valueTreeSelect: PropTypes.number,
  onChangeTreeSelect: PropTypes.func,
  optionSelect: PropTypes.array,
  onChangeSelect: PropTypes.func,
  valueSelect: PropTypes.array,
  hasButtonAdd: PropTypes.bool,
  isSelect: PropTypes.bool,
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
  treeNodeFilterProp: PropTypes.string,
  filterTreeNode: PropTypes.func,
  chilDren: PropTypes.string,
  buttonExport: PropTypes.bool,
  dataSearch: PropTypes.object,
  dataExport: PropTypes.object,
};

export default ComTable;
