/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import CompanyForm from './Components/CompanyForm';
import CompanyList from './Components/CompanyList';

export const CompanyContext = createContext();
function ManageCompany() {
  const { token } = useSelector(authStore);
  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [listCompany, setListCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataEmpty = [];
  const showDataCompany = (dataCompany) => {
    setListCompany(dataCompany);
  };

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, [timeOut]);
  return (
    <>
      <div>
        <Modal
          className="modal-timeout"
          okText="はい"
          open={!isActive}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p className="text-center">タイムアウトしました。</p>
          <p className="text-center">再度ログインしてください。</p>
        </Modal>
      </div>

      <CompanyContext.Provider
        value={{ listCompany, showDataCompany, loading, setLoading }}
      >
        <div className="display-flex flex-column pl-100 pr-100 pb-100 full-height color-page ">
          <Modal
            title={isAddForm ? '会社追加' : '会社変更'}
            open={isModalOpen}
            onCancel={closeModal}
            className="modal-add modal-singer"
            footer={null}
          >
            <CompanyForm
              closeModal={closeModal}
              isAddForm={isAddForm}
              showModal={showModal}
              initialValues={isAddForm ? dataEmpty : listCompany}
            />
          </Modal>
          <CompanyList
            showModal={showModal}
            setIsAddForm={setIsAddForm}
            closeModal={closeModal}
          />
        </div>
      </CompanyContext.Provider>
    </>
  );
}

ManageCompany.propTypes = {};

export default ManageCompany;
