/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { t } from 'i18next';
import jwt_decode from 'jwt-decode';
import AddUserForm from './Components/AddUserForm';
import UserTables from './Components/UserTable';

export const UserContext = createContext();

function ManageUsers() {
  const { token } = useSelector(authStore);

  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [listUser, setListUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showDataUser = (dataUser) => {
    setListUser(dataUser);
  };
  const [isActive, setIsActive] = useState(true);

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    if (isModalOpen === false) {
      setFileList([]);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal
        open={!isActive}
        okText="はい"
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-timeout"
      >
        <p className="text-center">タイムアウトしました。</p>
        <p className="text-center">再度ログインしてください。</p>
      </Modal>
      <UserContext.Provider
        value={{ listUser, showDataUser, loading, setLoading }}
      >
        <div className="display-flex flex-column pl-100 full-height pr-100 pb-100 color-page">
          <Modal
            title={
              isAddForm
                ? t('dashboard.tab2.form.addUser')
                : t('dashboard.tab2.form.editUser')
            }
            open={isModalOpen}
            onCancel={closeModal}
            className="modal-add"
            footer={null}
          >
            <AddUserForm
              closeModal={closeModal}
              isAddForm={isAddForm}
              isModalOpen={isModalOpen}
              setFileList={setFileList}
              fileList={fileList}
            />
          </Modal>
          <UserTables
            showModal={showModal}
            closeModal={closeModal}
            setIsAddForm={setIsAddForm}
          />
        </div>
      </UserContext.Provider>
    </>
  );
}

export default ManageUsers;
