import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

function ComButton({ children, className = '', size = 'large', ...props }) {
  return (
    <Button
      className={`com-btn ${className}`}
      size={size}
      type="primary"
      color="white"
      {...props}
    >
      {children}
    </Button>
  );
}

ComButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
};

export default ComButton;
