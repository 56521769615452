/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import ComTable from '@components/common/ComTable';
import { fabApi } from '@services/fabApi';
import PropTypes from 'prop-types';
import STATUSCODE from '@common/constant/status';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { timeDateDisplay } from '@common/utils/dateHelper';
import { IconEditBig } from '@assets/icons';
import customTooltip from '@common/utils/customTooltip';
import { FabContext } from '..';

function FabsList({ showModal, setIsAddForm }) {
  const { state } = useLocation();
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    layout_id: state?.layout_id,
    company: '',
    order_by: 'id',
  });

  const [optionSelect, setOptionSelect] = useState([]);
  const { showDataFab, loading } = useContext(FabContext);

  const { loadingTable, value } = useFetch(
    () => fabApi.getListFabs(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.room_name,
      pagination.company,
      pagination.page_size,
      pagination.company,
      pagination.order_by,
      pagination.keyword,
      pagination.layout_id,
      loading,
    ],
  );

  const paginationProp = {
    position: ['bottomRight'],
    total: value?.data?.count,
    pageSize: pagination?.page_size,
    currentPage: pagination?.page_number,
  };

  const handleSearch = (values) => {
    setPagination({
      ...pagination,
      room_name: values,
      keyword: values,
      page_number: 1,
    });
  };
  const checkColumnSorted = pagination?.order;
  const onChangeSelect = (data) => {
    setPagination({ ...pagination, layout_id: data });
  };

  const handleChangePagination = (currentPage, pageSize) => {
    setPagination((curState) => ({
      ...curState,
      page_size: pageSize,
      page_number: currentPage,
    }));
  };

  const hanldeChangeTable = (_, filter, sorter) => {
    if (sorter?.order === 'descend') {
      setPagination({ ...pagination, order: 'DESC', order_by: sorter?.field });
    } else {
      setPagination({ ...pagination, order: 'ASC', order_by: sorter?.field });
    }
  };
  const onRow = async (values) => {
    setIsAddForm(false);
    try {
      const res = await fabApi.getDetailFabs(values?.id);
      const statusRes = res?.statusCode;
      if (statusRes === STATUSCODE.SUCCESS) {
        showDataFab({
          ...res?.data,
        });
        showModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      key: 'id',
      width: 70,
      render: (values) => {
        return <div className="display-flex jc-center">{values}</div>;
      },
    },
    {
      title: t('layout.table.fabName'),
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      key: 'name',
      width: 700,
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: t('layout.table.createDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 300,
      render: (values) => {
        return <div>{timeDateDisplay(values)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 88,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            onRow(record);
          },
        };
      },
    },
  ];

  return (
    <div className="fabs-list-wrapper full-width">
      <ComTable
        title={
          state?.layout_name
            ? `${'Fab'} ${'-'} ${state?.layout_name}`
            : `${'Fab'}`
        }
        loading={loadingTable}
        columns={columns}
        data={value?.data?.data}
        pagination={paginationProp}
        onSearch={handleSearch}
        optionSelect={optionSelect}
        onChangeSelect={onChangeSelect}
        onChangeTable={hanldeChangeTable}
        onChangePagination={handleChangePagination}
        showModal={showModal}
        setIsAddForm={setIsAddForm}
        // onClickRow={onRow}
      />
    </div>
  );
}
FabsList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
};

export default FabsList;
