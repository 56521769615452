/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import TextField from '@components/common/ComTextField';
import ComTextFieldGroup from '@components/common/ComTextFieldGroup';
import { fabApi } from '@services/fabApi';
import {
  Card,
  Col,
  ConfigProvider,
  Form,
  message,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import localeValues from 'antd/lib/locale/ja_JP';
import { validateFloat, validateMax } from '@common/utils/validate';
import STATUSCODE from '@common/constant/status';
import SelectField from '@components/common/ComSelectField';
import ComTextFieldGroup2 from '@components/common/ComTextFieldGroup2';
import { t } from 'i18next';
import layoutApi from '@services/layout';
import ComButton from '@components/common/ComButton';
import uploadApi from '@services/uploadApi';
import ComUploadFile from '@components/common/ComUploadFile';
import { FabContext } from '..';

const { Text, Link } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 16 },
};
function FabsForm({ closeModal, isAddForm, initialValues, defaultLayout }) {
  const [form] = Form.useForm();
  const obj = Form.useWatch('assetbundle_link', form);
  const [fileList, setFileList] = useState([]);
  const { fab, setLoading, loading } = useContext(FabContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [listLayout, setListLayout] = useState();

  function formatStringToNumber(jsonObj) {
    const formattedObj = { ...jsonObj };
    // eslint-disable-next-line no-restricted-syntax
    for (const key in formattedObj) {
      if (
        key !== 'name' &&
        key !== 'layout_id' &&
        typeof formattedObj[key] === 'string'
      ) {
        const parsedNumber = parseFloat(formattedObj[key]);
        // eslint-disable-next-line no-restricted-globals
        formattedObj[key] = isNaN(parsedNumber)
          ? formattedObj[key]
          : parsedNumber;
      }
    }
    return formattedObj;
  }
  const createLayout = async (values) => {
    const { assetbundle_link, ...data } = values;
    try {
      if (fileList?.length) {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        const res = await uploadApi.uploadFile(formData);
        const resFab = await fabApi.postNewFabs({
          ...formatStringToNumber(data),
          assetbundle_link: res?.data?.url,
        });
        if (resFab?.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('fab.message.addSuccess'));
          closeModal();
          setLoading(false);
          setFileList([]);
          setIsEditMode(false);
          form.resetFields();
        }
      } else {
        setLoading(true);
        const res = await fabApi.postNewFabs(formatStringToNumber(values));
        if (res?.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('fab.message.addSuccess'));
          closeModal();
          setLoading(false);
          setFileList([]);
          setIsEditMode(false);
          form.resetFields();
        }
      }
    } catch (err) {
      if (err?.response?.data?.statusCode === STATUSCODE.UNPROCESSABLE) {
        message.error(t('fab.message.error422'));
      }
      if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error(t('fab.message.addFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const editLayout = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    try {
      setLoading(true);
      let assetbundleLink = null;

      if (fileList.length > 0) {
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);

        const res = await uploadApi.uploadFile(formData);
        assetbundleLink = res?.data?.url;
      }

      const resFab = await fabApi.editFab(initialValues?.id, {
        ...formatStringToNumber(trimmedValues),
        assetbundle_link: fileList.length > 0 ? assetbundleLink : obj || null,
      });
      if (resFab?.statusCode === STATUSCODE.SUCCESS) {
        message.success(t('fab.message.editSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error(t('fab.message.editFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (!isEditMode) {
      createLayout(trimmedValues);
    } else {
      editLayout(trimmedValues);
    }
  };

  const layoutListOption = useMemo(
    () =>
      listLayout?.map((e) => ({
        label: e.name,
        value: e.id,
        ...e,
      })),
    [listLayout],
  );
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await fabApi.deleteFab(id);
      if (res.statusCode === STATUSCODE.SUCCESS) {
        message.success('Fabの削除に成功しました。');
        closeModal();
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error('Fabの削除に失敗しました。');
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択したFabを削除しますか？削除したFabはもとには戻せません。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await layoutApi.getListLayout();
        setListLayout(result?.data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!isEditMode) {
      form.resetFields();
      setFileList([]);
      form.setFieldValue('layout_id', defaultLayout?.value);
    } else {
      setFileList([]);
      form.setFieldsValue(initialValues);
    }
  }, [defaultLayout, form, initialValues, isEditMode]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues)?.length);
  }, [fab, initialValues]);
  return (
    <ConfigProvider locale={localeValues}>
      <Card>
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          colon={false}
          className="form-fabs pl-30 pr-30"
          validateTrigger="onChange"
          labelCol={{ span: 8 }}
        >
          <Row gutter={[48, 24]} className="pb-20">
            <Col span={12}>
              <TextField
                label={t('layout.table.fabName')}
                name="name"
                labelAlign="left"
                labelCol={{ span: 6 }}
                rules={[
                  {
                    validator: (rule, value, callback) =>
                      validateMax(
                        rule,
                        value,
                        callback,
                        30,
                        t('layout.table.fabName'),
                      ),
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <SelectField
                label={t('layout.form.layoutName')}
                name="layout_id"
                labelAlign="left"
                labelCol={{ span: 6 }}
                options={layoutListOption}
                rules={[
                  {
                    required: true,
                    message:
                      t('layout.form.layoutName') + t('message.isRequired'),
                  },
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[48, 16]} className="pb-30">
            <Col span={24}>
              <Space direction="vertical">
                <Text>プレイヤーの初期生成設定</Text>
              </Space>
            </Col>
          </Row>
          <Col span={24}>
            <ComTextFieldGroup
              label={
                <Space direction="vertical">
                  <Text>{t('fab.form.playerPos')}</Text>
                </Space>
              }
              name="player_pos"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.playerPos'),
                    ),
                },
              ]}
              form={form}
              validateY
            />
          </Col>
          <Col span={24}>
            <ComTextFieldGroup
              label={
                <Space direction="vertical">
                  <Text>{t('fab.form.playerRota')}</Text>
                </Space>
              }
              name="player_rota"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.playerRota'),
                    ),
                },
              ]}
            />
          </Col>
          <Col span={24} className="pb-20">
            <Space direction="vertical">
              <Text>マップ設定</Text>
            </Space>
          </Col>
          <Col span={24}>
            <ComTextFieldGroup
              label={t('fab.form.playerPortrait')}
              name="player_portrait_pos"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.playerPortrait'),
                    ),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <ComTextFieldGroup
              label={
                <Space direction="vertical">
                  <Text>{t('fab.form.playerLandScape')}</Text>
                </Space>
              }
              name="player_landscape_pos"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.playerLandScape'),
                    ),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <ComTextFieldGroup
              label={
                <Space direction="vertical">
                  <Text>{t('fab.form.rotation')}</Text>
                </Space>
              }
              name="rotation"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.rotation'),
                    ),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <ComTextFieldGroup2
              label={
                <Space direction="vertical">
                  <Text>{t('fab.form.playerMinmax')}</Text>
                </Space>
              }
              form={form}
              name="player_portrait_pos"
              className="pb-30"
              rules={[
                {
                  validator: (rule, value, callback) =>
                    validateFloat(
                      rule,
                      value,
                      callback,
                      t('fab.form.playerMinmax'),
                    ),
                },
              ]}
            />
          </Col>
          <Col span={17}>
            <ComUploadFile
              label="アセットバンドル"
              name="assetbundle_link"
              fileList={
                (obj !== undefined &&
                  obj !== null && [
                    {
                      url: obj,
                      thumbUrl: obj,
                      name:
                        (obj && new URL(obj).pathname.split('/').pop()) || '',
                    },
                  ]) ||
                fileList
              }
              setFileList={setFileList}
              isFab
            />
          </Col>
          <div className="full-width button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              {isEditMode ? '変更' : '追加'}
            </ComButton>
            {isEditMode !== 0 && (
              <ComButton
                onClick={() => confirmDelete()}
                className="save-btn btn-delete mr-40"
              >
                削除
              </ComButton>
            )}
          </div>
        </Form>
      </Card>
    </ConfigProvider>
  );
}
FabsForm.propTypes = {
  closeModal: PropTypes.func,
  isAddForm: PropTypes.bool,
  initialValues: PropTypes.array,
  defaultLayout: PropTypes.object,
};
export default FabsForm;
