import React, { useEffect } from 'react';
import { PATH_URL } from '@common/config/pathURL';
import authApi from '@services/auth';
import { authStore, loginSuccess } from '@store/slices/authSlice';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconMicrosoft } from '@assets/icons';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@common/config/auth';
import logo from '../../assets/imgs/login_logo_img.png';

function Login() {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const navigate = useNavigate();
  const { token, user_type_id } = useSelector(authStore);
  const handleSubmit = async (value) => {
    try {
      const res = await authApi.login(value);
      dispath(loginSuccess(res));
    } catch (error) {
      const messageRes = error?.response?.data?.details?.message[0];
      if (messageRes === 'user_not_found' || messageRes === 'password_wrong') {
        message.error('IDもしくはパスワードが間違っています。');
      }
      if (messageRes === 'inactive_user') {
        message.error(t('login.msgInactive'));
      }
    }
  };
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      if (response?.accessToken) {
        const res = await authApi.loginMS({
          accessToken: response?.accessToken,
        });
        dispath(loginSuccess(res));
      }
    } catch (e) {
      console.error(e);
      message.error('FaViewにメールアドレスが登録されていません。');
    }
  };

  useEffect(() => {
    if (token) {
      navigate(
        user_type_id === 1
          ? PATH_URL.MANAGER_USERS.INDEX
          : PATH_URL.ACCOUNT.INDEX,
      );
    }
  }, [token, user_type_id]);

  return (
    <div className="login">
      <div className="logo-wrapper display-flex flex-column jc-center item-center mt-50 mb-70">
        <img src={logo} width="25%" alt="" />
      </div>
      <div className="login-form-wrapper display-flex flex-column flex-center px-30 mt-10">
        <div className="btn-login-ms mt-10" onClick={handleLogin}>
          <div className="icon-ms">
            <IconMicrosoft />
          </div>
          <div className="text">Microsoftでサインイン</div>
        </div>
      </div>
    </div>
  );
}

export default Login;
