export const x = () => null;
const primaryColor = '#58397c';

export const theme = {
  token: {
    colorPrimary: primaryColor,
    borderRadius: 0,
    colorPrimaryBg: primaryColor,
    colorPrimaryBgHover: primaryColor,
    fontFamily: 'Noto Sans JP, sans-serif',
  },
  components: {},
};
