import { t } from 'i18next';
import validator from 'validator';

export const validateFloat = (rule, value, callback, fieldName) => {
  const val = value?.toString()?.trim();

  if (!val && fieldName !== t('fab.form.playerMinmax')) {
    callback(`${fieldName}は必須入力です。`);
  }
  if (val && !validator.isFloat(val)) {
    callback('正しいフォーマットで入力してください。');
  } else {
    callback();
  }
};

export const validateMin = (rule, value, callback, length, fieldName) => {
  if (!value?.trim()) {
    callback(`${fieldName}は必須入力です。`);
  }
  if (value && value?.trim().length < length) {
    callback(`${fieldName} phải có ít nhất ${length}  kí tự`);
  } else {
    callback();
  }
};

export const validateMax = (rule, value, callback, length, fieldName) => {
  if (
    (value &&
      value?.trim().length > length &&
      fieldName === t('dashboard.tab2.form.userId')) ||
    (value &&
      value?.trim().length > length &&
      fieldName === t('user.message.userName'))
  ) {
    callback(`${fieldName}は${length}文字数以下で入力して下さい`);
  }
  if (!value?.trim()) {
    callback(`${fieldName}は必須入力です。`);
  }
  if (value && value?.trim().length > length) {
    callback(`${fieldName}は${length}文字未満で入力してください。`);
  } else {
    callback();
  }
};

export const validateRange = (
  rule,
  value,
  callback,
  min,
  max,
  fieldName,
  isEditMode,
) => {
  if (isEditMode) {
    if (value?.trim().length < min || value?.trim().length > max) {
      callback('パスワードの文字数は６～３０文字の範囲になります。');
    } else {
      callback();
    }
  } else {
    if (!value?.trim()) {
      callback(`${fieldName}は必須入力です。`);
    }
    if (value?.trim().length < min || value?.trim().length > max) {
      callback('パスワードの文字数は６～３０文字の範囲になります。');
    } else {
      callback();
    }
  }
};
export const validatePassWord = (
  rule,
  value,
  callback,
  min,
  max,
  fieldName,
) => {
  if (
    (value && value?.trim().length < min) ||
    (value && value?.trim().length > max)
  ) {
    callback('パスワードの文字数が６－３０です。');
  } else {
    callback();
  }
};
export const validateNoJapaneseFullWidth = (rule, value, callback) => {
  const regex = /[\uff01-\uff5e\uff61-\uff9f]/g;
  if (value && regex.test(value)) {
    callback('半角英数字のみが入力できます。');
  } else {
    callback();
  }
};
