import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber } from 'antd';

function TextField({
  label,
  name,
  placeholder,
  rules,
  dependencies,
  labelAlign,
  labelCol,
  isInputNumber,
  disabled,
  textPlaceHolder,
  noCopy,
}) {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      dependencies={dependencies}
      labelAlign={labelAlign}
      labelCol={labelCol}
      className={textPlaceHolder && 'text-placeholder'}
    >
      <Input
        placeholder={placeholder}
        disabled={disabled}
        onCopy={(e) => {
          if (noCopy) {
            e.preventDefault();
            return false;
          }
          return false;
        }}
      />
    </Form.Item>
  );
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.array,
  dependencies: PropTypes.array,
  labelAlign: PropTypes.string,
  labelCol: PropTypes.object,
  isInputNumber: PropTypes.bool,
  disabled: PropTypes.bool,
  textPlaceHolder: PropTypes.bool,
  noCopy: PropTypes.bool,
};

export default TextField;
