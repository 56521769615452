/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Form, Input, Modal, message } from 'antd';
import {
  validateMax,
  validateNoJapaneseFullWidth,
  validatePassWord,
  validateRange,
} from '@common/utils/validate';
import ComButton from '@components/common/ComButton';
import SelectField from '@components/common/ComSelectField';
import TextField from '@components/common/ComTextField';
import ComUpload from '@components/common/ComUpload';
import userApi from '@services/userApi';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import companyApi from '@services/company';
import departmentApi from '@services/department';
import STATUSCODE from '@common/constant/status';
import { DATE_TIME_FORMAT } from '@common/constant/date';
import dayjs from 'dayjs';
import { UserContext } from '..';

const roleOptions = [
  { label: 'Admin', value: 1 },
  { label: 'User', value: 2 },
];
function UserForm({ initialValues, closeModal }) {
  const [fileList, setFileList] = useState([]);
  const { setLoading, showDataUser, loading } = useContext(UserContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataAccount, setdataAccount] = useState();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [listCompany, setListCompany] = useState();
  const [listDepartment, setListDepartment] = useState();
  const avt = Form.useWatch('avatar', form);
  const watchDepartment = Form.useWatch('department_id', form);

  const watchCompany = Form.useWatch('company_id', form);
  const companyListOpton = useMemo(
    () =>
      listCompany?.map((e) => ({
        label: e.name,
        value: e.id,
        ...e,
      })),
    [listCompany],
  );
  const filterDepartments = listDepartment?.filter(
    (x) => x.company_id === watchCompany,
  );
  const departmentListOpton = useMemo(
    () =>
      filterDepartments?.map((e) => ({
        label: e.name,
        value: Number(e.id),
        ...e,
      })),
    [filterDepartments],
  );
  const messError = {
    400: 'このメールアドレスは既に登録されています。',
    422: t('user.message.mesageUnprocessable'),
    500: t('user.message.mesageAddFail'),
  };
  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (!isEditMode) {
      const { avatar, ...data } = trimmedValues;
      const params = {
        ...data,
        invalid: false,
        re_password: data.password,
      };
      try {
        setLoading(true);
        const res = await userApi.addNewUser({
          ...params,
          department_id: watchDepartment ? data?.department_id : null,
        });
        if (res?.statusCode === STATUSCODE.SUCCESS) {
          const formData = new FormData();
          formData.append('file', avatar[0]?.originFileObj);
          message.success(t('user.message.mesageAddSuccess'));
          window.scrollTo(0, document.body.scrollHeight);
          setFileList([]);
          setIsEditMode(false);
          if (avatar.length > 0) {
            await userApi.uploadImage(res?.data?.id, formData);
          }
          closeModal();
          form.resetFields();
          form.setFieldValue('avatar', []);
        }
      } catch (err) {
        message.error(messError[err?.response?.data?.statusCode]);
      } finally {
        setLoading(false);
      }
    } else {
      const { avatar, password, ...data } = trimmedValues;
      const params = password
        ? {
            ...data,
            invalid: false,
            password,
            re_password: password,
          }
        : { ...data, invalid: false };
      const checkData = watchDepartment
        ? params
        : { ...params, department_id: null };
      try {
        window.scrollTo(0, document.body.scrollHeight);
        setLoading(true);
        const res = await userApi.editUser(initialValues?.id, checkData);
        if (res?.statusCode === STATUSCODE.SUCCESS) {
          if (avatar[0]?.originFileObj) {
            const formData = new FormData();
            formData.append('file', avatar[0]?.originFileObj);
            await userApi.uploadImage(res?.data?.id, formData);
          }
          if (avatar?.length === 0) {
            await userApi.deleteAvatar(res?.data?.id);
          }
          message.success(t('user.message.mesageEditSuccess'));
          form.resetFields();
          form.setFieldValue('avatar', []);
          setIsEditMode(false);
          setLoading(false);
        }
        closeModal();
        showDataUser({});
      } catch (error) {
        setLoading(false);
        if (error?.response.data.statusCode === STATUSCODE.BAD_REQUEST) {
          message.error('ユーザーを更新できませんでした');
        }
        if (error?.response.data.statusCode === STATUSCODE.SERVER_ERROR) {
          message.error(t('user.message.mesageEditFail'));
        }
      } finally {
        setFileList([]);
        setLoading(false);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {};
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await userApi.deleteUser(id);
      if (res.statusCode === STATUSCODE.SUCCESS) {
        message.success('ユーザーの削除に成功しました。');
        closeModal();
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error('ユーザーの削除に失敗しました。');
      }
      if (error.response.status === STATUSCODE.UNPROCESSABLE) {
        message.error('ログイン中のユーザーのため削除ができませんでした。');
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択したユーザーを削除しますか？削除したユーザーはもとには戻せません。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 5 },
    },
    labelAlign: 'left',
  };
  const getDataAccount = async () => {
    const data = await userApi.getMe();
    setdataAccount(data?.data);
  };

  useEffect(() => {
    if (!isEditMode) {
      form.resetFields();
      form.setFieldValue('avatar', []);
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, isEditMode]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues).length);
  }, [initialValues]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = companyApi.getListCompany();
        const department = departmentApi.getListDepartment();
        const [result1, result2] = await Promise.all([company, department]);

        setListCompany(result1.data.data);
        setListDepartment(result2.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    getDataAccount();
  }, []);
  const handleFormValuesChange = (changedValues, allValues) => {
    const fieldActions = {
      company_id: () => form.setFieldValue('department_id', ''),
      user_id: () => form.validateFields(['re_user_id']),
      password: () => form.validateFields(['re_password']),
    };

    Object.keys(changedValues).forEach((key) => {
      if (key in fieldActions) {
        fieldActions[key]();
      }
    });
  };
  return (
    <Card>
      <Form
        name="tab2-form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        colon={false}
        className="user-form"
        validateTrigger="onChange"
        onValuesChange={handleFormValuesChange}
      >
        <TextField
          {...configTextField}
          label={t('dashboard.tab2.form.userId')}
          name="user_id"
          labelCol={{ span: 10 }}
          noCopy
          disabled={isEditMode}
          rules={[
            {
              type: 'email',
              message: t('user.message.requiredEmail'),
            },
            {
              validator: (rule, value, callback) =>
                validateMax(
                  rule,
                  value,
                  callback,
                  50,
                  t('dashboard.tab2.form.userId'),
                ),
            },
          ]}
        />
        {!isEditMode && (
          <TextField
            {...configTextField}
            label="メールアドレス（再）"
            name="re_user_id"
            labelCol={{ span: 10 }}
            rules={[
              {
                type: 'email',
                message: t('user.message.requiredEmail'),
              },
              isEditMode
                ? {
                    validator(rule, value, callback) {
                      const user_id = form.getFieldValue('user_id');
                      if (!value) {
                        callback('メールアドレス（再）は必須入力です。');
                      } else if (value.length > 50) {
                        callback(
                          'メールアドレスは50文字以下でなければなりません。',
                        );
                      } else if (user_id && value && user_id !== value) {
                        callback(
                          '新メールアドレスと再入力メールアドレスが一致しません。',
                        );
                      } else {
                        callback();
                      }
                    },
                  }
                : {
                    validator(rule, value, callback) {
                      const user_id = form.getFieldValue('user_id');
                      if (!value) {
                        callback('メールアドレス（再）は必須入力です。');
                      } else if (value.length > 50) {
                        callback(
                          'メールアドレスは50文字以下でなければなりません。',
                        );
                      } else if (value && user_id && user_id !== value) {
                        callback(
                          '新メールアドレスと再入力メールアドレスが一致しません。',
                        );
                      } else {
                        callback();
                      }
                    },
                  },
            ]}
          />
        )}
        <TextField
          {...configTextField}
          label="氏名（英語表記・名姓)"
          name="username"
          labelCol={{ span: 10 }}
          placeholder={dataAccount?.username}
          rules={[
            {
              validator: (rule, value, callback) =>
                validateMax(
                  rule,
                  value,
                  callback,
                  50,
                  '氏名（英語表記・名姓)',
                  'fullWidth',
                ),
            },
            {
              validator: (rule, value, callback) =>
                validateNoJapaneseFullWidth(rule, value, callback),
            },
          ]}
        />
        <SelectField
          {...configTextField}
          labelCol={{ span: 10 }}
          label={t('dashboard.tab2.form.companyName')}
          name="company_id"
          options={companyListOpton || []}
          rules={[
            { required: true, message: t('user.message.needpermission') },
          ]}
        />
        <SelectField
          {...configTextField}
          labelCol={{ span: 10 }}
          label={t('dashboard.tab2.form.department')}
          name="department_id"
          options={departmentListOpton || []}
          disabled={!watchCompany}
        />
        <TextField
          {...configTextField}
          label={t('dashboard.tab2.form.initial')}
          name="initial"
          placeholder={dataAccount?.initial}
          rules={[
            {
              validator: (rule, value, callback) =>
                validateMax(
                  rule,
                  value,
                  callback,
                  2,
                  t('dashboard.tab2.form.initial'),
                ),
            },
            {
              validator: (rule, value, callback) =>
                validateNoJapaneseFullWidth(rule, value, callback),
            },
          ]}
        />
        <SelectField
          {...configTextField}
          labelCol={{ span: 10 }}
          label={t('dashboard.tab2.form.role')}
          name="user_type_id"
          options={roleOptions || []}
          rules={[{ required: true, message: t('user.message.authorization') }]}
        />
        <ComUpload
          label=" 顔写真"
          name="avatar"
          fileList={avt || []}
          setFileList={setFileList}
          className="form-upload-user"
        />
        {isEditMode !== 0 && (
          <>
            <TextField
              {...configTextField}
              label="登録者"
              labelCol={{ span: 10 }}
              placeholder={
                initialValues?.create_user?.username
                  ? initialValues?.create_user?.username
                  : initialValues?.create_user
              }
              disabled
              textPlaceHolder
            />
            <TextField
              {...configTextField}
              label="登録日"
              labelCol={{ span: 10 }}
              placeholder={dayjs(initialValues?.createdAt).format(
                DATE_TIME_FORMAT,
              )}
              disabled
              textPlaceHolder
            />
          </>
        )}
        <Form.Item className="save-btn-wrapper">
          <ComButton disabled={loading} htmlType="submit" className="save-btn">
            {isEditMode ? t('user.button.edit') : t('user.button.add')}
          </ComButton>
          {isEditMode !== 0 && (
            <ComButton
              onClick={() => confirmDelete()}
              className="save-btn btn-delete"
            >
              削除
            </ComButton>
          )}
        </Form.Item>
      </Form>
    </Card>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.any,
  closeModal: PropTypes.func,
  isAddForm: PropTypes.bool,
};

export default UserForm;
