import api from './baseApi';

export const authApi = {
  login(params) {
    return api.post('/v1/auth-user/login', params);
  },
  logout() {
    return api.get('/v1/auth-user/logout');
  },
  loginMS(params) {
    return api.get('/v1/oauth2/microsoft/callback', { params });
  },
};

export default authApi;
