/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import jwt_decode from 'jwt-decode';
import Account from './Account';
import AccountForm from './AccountForm';

export const AccountContext = createContext();

function ManageAccount() {
  const { token } = useSelector(authStore);

  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [listUser, setListUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showDataAccount = (dataUser) => {
    setListUser(dataUser);
  };
  const [isActive, setIsActive] = useState(true);

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    if (isModalOpen === false) {
      setFileList([]);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal
        open={!isActive}
        okText="はい"
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-timeout"
      >
        <p className="text-center">タイムアウトしました。</p>
        <p className="text-center">再度ログインしてください。</p>
      </Modal>
      <AccountContext.Provider
        value={{ showDataAccount, loading, setLoading, listUser }}
      >
        <Modal
          title=" アカウント変更"
          open={isModalOpen}
          onCancel={closeModal}
          className="modal-add"
          footer={null}
        >
          <AccountForm
            closeModal={closeModal}
            isModalOpen={isModalOpen}
            initialValues={listUser}
          />
        </Modal>
        <Account
          showModal={showModal}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
        />
      </AccountContext.Provider>
    </>
  );
}

export default ManageAccount;
