/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_URL } from '@common/config/pathURL';
import departmentApi from '@services/department';
import STATUSCODE from '@common/constant/status';
import { IconAdd, IconEditBig } from '@assets/icons';
import { Table, ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import { timeDateDisplay } from '@common/utils/dateHelper';
import customTooltip from '@common/utils/customTooltip';
import { DepartmentContext } from '..';

function DepartmentList({ showModal, setIsAddForm, company_id }) {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    company_id: Number(company_id),
    order_by: 'createdAt',
  });
  const [showTooltip, setShowTooltip] = useState(true);
  const { showDataDepartment, loading } = useContext(DepartmentContext);
  const company_id_filter = company_id;
  const { value } = useFetch(
    () => departmentApi.getListDepartment(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.name,
      pagination.company_id,
      pagination.page_size,
      pagination.order_by,
      pagination.keyword,
      loading,
      company_id,
    ],
  );

  const checkColumnSorted = pagination?.order;

  const hanldeChangeTable = (_, filter, sorter) => {
    if (sorter?.order === 'descend') {
      setPagination({ ...pagination, order: 'DESC', order_by: sorter?.field });
    } else {
      setPagination({ ...pagination, order: 'ASC', order_by: sorter?.field });
    }
  };
  const showDetail = async (values) => {
    if (values?.name !== '無所属') {
      setIsAddForm(false);
      try {
        const res = await departmentApi.getDetailDepartment(values?.id);
        const statusRes = res?.statusCode;
        if (statusRes === STATUSCODE.SUCCESS) {
          showDataDepartment({
            ...res?.data,
          });
          showModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const openModalAdd = () => {
    setIsAddForm(true);
    showModal(true);
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'Script error.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const columns = [
    // {
    //   width: 65,
    // },
    {
      title: (
        <div className="display-flex jc-space-btwn ">
          <span className="display-flex item-center">
            {t('dashboard.tab2.form.department')}
          </span>
          <span className="pointer mr-10" onClick={(e) => e.stopPropagation()}>
            <IconAdd
              onMouseEnter={() => setShowTooltip(false)}
              onMouseOut={() => setShowTooltip(true)}
              onClick={() => openModalAdd()}
            />
          </span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: windowWidth >= 1920 ? 440 : 445,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: showTooltip && {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.numberUsers'),
      dataIndex: 'user_count',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 206,
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'user_count')}
          </div>
        ),
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate(PATH_URL.MANAGER_USERS.INDEX, {
              state: {
                department_id: record?.id,
                departmentName: record?.name,
                company_ids: company_id_filter,
              },
            });
          },
          style: { cursor: 'pointer' },
        };
      },
    },

    {
      title: t('dashboard.tab2.form.dateCreate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 206,
      render: (text, index) => {
        return index?.id === 0 ? '-' : <div>{timeDateDisplay(text)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 88,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record) => {
        return {
          onClick: () => {
            showDetail(record);
          },
        };
      },
    },
  ];
  const rowClassName = (record, rowIndex) => {
    return record?.id === 0 ? 'disabled-row' : '';
  };

  return (
    <div className="department-list-wrapper">
      <ConfigProvider locale={locale}>
        <div className="card-wrapper-department">
          <Table
            className="table-custom"
            rowKey={(d) => d.id}
            showHeader
            columns={columns}
            dataSource={value?.data?.data}
            onChange={hanldeChangeTable}
            showModal={showModal}
            setIsAddForm={setIsAddForm}
            pagination={false}
            rowClassName={rowClassName}
            showSorterTooltip={false}
            // loading={tableLoading}
          />
        </div>
      </ConfigProvider>
    </div>
  );
}

DepartmentList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
  company_id: PropTypes.number,
};

export default DepartmentList;
