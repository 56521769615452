import React from 'react';
import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import validator from 'validator';
import TextField from './ComTextField';

function ComTextFieldGroup2({ label, name, rules, form }) {
  return (
    <Form.Item
      label={label}
      labelAlign="left"
      labelCol={{ span: 5 }}
      className="form-group mb-40"
    >
      <Row>
        <Col xs={8} className="pl-40">
          <TextField
            label="最大"
            name="pos_y_max"
            rules={rules}
            labelAlign="left"
            labelCol={{ span: 5 }}
          />
        </Col>
        <Col xs={8} className="pl-40">
          <TextField
            label="最小"
            name="pos_y_min"
            rules={[
              {
                validator(rule, value, callback) {
                  const pos_y_min = form.getFieldValue('pos_y_min');
                  const pos_y_max = form.getFieldValue('pos_y_max');
                  const val = value?.toString()?.trim();
                  if (val && !validator.isFloat(val)) {
                    callback('正しいフォーマットで入力してください。');
                  }
                  if (parseFloat(pos_y_min) >= parseFloat(pos_y_max)) {
                    callback('上下方向制限の最小が最大より大きいです。');
                  } else {
                    callback();
                  }
                },
              },
            ]}
            labelAlign="left"
            labelCol={{ span: 5 }}
          />
        </Col>
      </Row>
    </Form.Item>
  );
}

ComTextFieldGroup2.propTypes = {
  label: PropTypes.node,
  rules: PropTypes.array,
  name: PropTypes.string,
  form: PropTypes.object,
};

export default ComTextFieldGroup2;
