/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useContext } from 'react';
import ROLE_ID from '@common/constant/role';
import useFetch from '@common/hooks/useFetch';
import userApi from '@services/userApi';
import { Image, message, Modal, Switch } from 'antd';
import PropTypes from 'prop-types';
import ComTable from '@components/common/ComTable';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import companyApi from '@services/company';
import STATUSCODE from '@common/constant/status';
import departmentApi from '@services/department';
import { IconEditBig } from '@assets/icons';
import customTooltip from '@common/utils/customTooltip';
import { UserContext } from '..';

function UserTables({ showModal, setIsAddForm }) {
  const location = useLocation();
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    keyword: '',
    company_ids: location?.state?.company_id,
    department_ids: location?.state?.department_id,
    user_type_ids: '',
    order_by: 'id',
  });

  const rootOptionValue1 = 'root1';
  const [optionFilter, setOptionFilter] = useState([
    {
      title: '権限',
      value: rootOptionValue1,
      key: rootOptionValue1,
      children: [
        {
          title: 'ADMIN',
          value: 'admin',
        },
        {
          title: 'USER',
          value: 'user',
        },
      ],
    },
  ]);

  const [valueSelect, setValueSelect] = useState(() => {
    if (location?.state?.company_ids && location?.state?.department_id) {
      return [
        Number(location?.state?.company_ids),
        `${location?.state?.department_id}_d`,
      ];
    }

    return (
      Number(location?.state?.company_ids) ||
      (location?.state?.department_id && `${location?.state?.department_id}_d`)
    );
  });

  useEffect(() => {
    if (location?.state?.department_id === 0) {
      setValueSelect(`${location?.state?.department_id}_d`);
    }
  }, [location?.state?.department_id]);

  const [listCompany, setListCommpany] = useState();
  const [listDepartment, setListDepartment] = useState();
  const { showDataUser, loading, setLoading } = useContext(UserContext);

  const { loadingTable, value } = useFetch(
    () => userApi.getListUser(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.keyword,
      pagination.user_type_ids,
      pagination.company_ids,
      pagination.department_ids,
      pagination.page_size,
      pagination.order_by,
      loading,
    ],
  );

  const paginationProp = {
    position: ['bottomRight'],
    total: value?.data?.count,
    pageSize: pagination?.page_size,
    currentPage: pagination?.page_number,
  };
  const checkColumnSorted = pagination?.order;
  const handleSearch = (keyWord) => {
    setPagination({ ...pagination, keyword: keyWord.trim(), page_number: 1 });
  };

  const onChangeTreeSelect = (params) => {
    setValueSelect(params);
    const filterCompany = listCompany?.data?.data.filter((item) =>
      params.includes(item?.id),
    );
    const paramCompany = filterCompany.map((x) => x.id).join(',');
    const filterDepartment = listDepartment?.data?.data.filter((item) =>
      params.includes(`${item?.id}_d`),
    );
    const paramDepartment = filterDepartment.map((x) => `${x?.id}_d`).join(',');
    const paramsFilter = params.join(',');

    if (paramsFilter.includes('admin') || paramsFilter.includes('user')) {
      if (paramsFilter.includes('admin')) {
        setPagination((prev) => ({ ...prev, user_type_ids: '1' }));
      }
      if (paramsFilter.includes('user')) {
        setPagination((prev) => ({ ...prev, user_type_ids: '2' }));
      }
      if (paramsFilter.includes('admin') && paramsFilter.includes('user')) {
        setPagination((prev) => ({ ...prev, user_type_ids: '' }));
      }
    } else {
      setPagination((prev) => ({ ...prev, user_type_ids: '' }));
    }
    setPagination((prev) => {
      if (paramsFilter === 'admin,user' || paramsFilter === 'user,admin') {
        return {
          ...prev,
          company_ids: paramCompany,
          department_ids: paramDepartment.replace(/_d/g, ''),
        };
      }
      return {
        ...prev,
        company_ids: paramCompany,
        department_ids: paramDepartment.replace(/_d/g, ''),
      };
    });
    setPagination((prev) => ({ ...prev, page_number: 1 }));
  };
  const filterTreeNode = (inputValue, treeNode) => {
    const title = treeNode?.props?.title.trim() || '';
    return title.toLowerCase().includes(inputValue.toLowerCase().trim());
  };
  const handleChangePagination = (currentPage, pageSize) => {
    setPagination((curState) => ({
      ...curState,
      page_size: pageSize,
      page_number: currentPage,
    }));
  };

  const handleChangeTable = (_, filter, sorter) => {
    const sortField = sorter?.field;
    const checkSortField = {
      company: 'company_name',
      department: 'department_name',
    };
    if (sorter?.order === 'descend') {
      setPagination({
        ...pagination,
        order: 'DESC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    } else {
      setPagination({
        ...pagination,
        order: 'ASC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    }
  };

  const handleCreate = () => {
    window.scrollTo(0, 0);
    showDataUser({});
  };

  const handleChangeStatus = async (data) => {
    const { id, invalid } = data;
    setLoading(true);
    try {
      if (invalid) {
        const resUnLock = await userApi.unLockUser(id);
        if (resUnLock.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('user.message.messageUnlock'));
        }
      } else {
        const resLock = await userApi.lockUser(id);
        if (resLock.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('user.message.messageLock'));
        }
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.SERVER_ERROR) {
        message.error(t('user.message.messsageServerError'));
      }
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error(t('user.message.messageBadRequest'));
      }
    } finally {
      setLoading(false);
    }
  };
  const confirmChangeStatus = (data) => {
    Modal.confirm({
      centered: true,
      content: (
        <div>
          {data?.invalid ? (
            <div className="flex-center flex-column modal-confirm-wrapper">
              <span>このアカウントを活性</span>
              <span>しますか。</span>
            </div>
          ) : (
            <div className="flex-center flex-column modal-confirm-wrapper">
              <span>このアカウントを非活性</span>
              <span>しますか。</span>
            </div>
          )}
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleChangeStatus(data),
    });
  };
  const showDetail = async (values) => {
    setIsAddForm(false);
    try {
      const res = await userApi.getDetailUser(values?.id);
      const statusRes = res?.statusCode;
      if (statusRes === STATUSCODE.SUCCESS) {
        showDataUser({
          ...res?.data,
          password: '',
          avatar: res.data.avatar
            ? [
                {
                  uid: 1,
                  name: ' ',
                  status: 'done',
                  url: res.data.avatar,
                },
              ]
            : [],
        });
        showModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: t('dashboard.tab2.form.avatar'),
      dataIndex: 'avatar',
      width: 72,
      render: (values) =>
        values && (
          <Image
            onClick={(e) => e.stopPropagation()}
            src={values}
            alt=""
            width={53}
            height={34}
          />
        ),
    },
    {
      title: t('dashboard.tab2.form.userId'),
      dataIndex: 'user_id',
      width: 350,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'user_id')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.userName'),
      width: 150,
      dataIndex: 'username',
      sorter: {
        multiple: true,
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'username')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.companyName'),
      width: 119,
      dataIndex: 'company',
      sorter: {
        multiple: true,
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return values?.name;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'company_name')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.department'),
      dataIndex: 'department',
      width: 99,
      sorter: {
        multiple: true,
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return values?.name;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'department_name')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.role'),
      width: 99,
      sorter: {
        multiple: true,
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'user_type_id',
      render: (values) => {
        return values === ROLE_ID.admin ? 'Admin' : 'User';
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'user_type_id')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.switch'),
      key: 'switchActive',
      width: 100,
      sorter: {
        multiple: true,
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      dataIndex: 'invalid',
      render: (values, index) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Switch
              className="w-32"
              checked={!values}
              onChange={() => confirmChangeStatus(index)}
            />
          </div>
        );
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'invalid')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 40,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record) => {
        return {
          onClick: () => {
            showDetail(record);
          },
        };
      },
    },
  ];

  const rowClassName = (record, index, event) => {
    if (event && event.target) {
      const { classList } = event.target;
      if (classList.contains('ant-table-row-hover')) {
        return 'flex jus table-row';
      }
    }
    return 'table-row-light';
  };

  const fetchData = async () => {
    try {
      const res = await companyApi.getListCompany();
      setListCommpany(res);
      const companyData = res.data.data.map((company) => ({
        id: company?.id,
        name: company?.name,
      }));
      const res2 = await departmentApi.getListDepartment();
      setListDepartment(res2);
      const departmentData = res2.data.data.map((department) => ({
        id: `${department?.id}_d`,
        name: department?.name,
      }));
      // const uniqueObjects = companyData.filter((data, index, self) => {
      //   const currentName = data.name;
      //   return self.findIndex((obj) => obj.name === currentName) === index;
      // });
      // const uniqueObjectsDepart = departmentData.filter((data, index, self) => {
      //   const currentName = data.name;
      //   return self.findIndex((obj) => obj.name === currentName) === index;
      // });
      const childrenCompanyData = companyData?.map((company) => ({
        label: company.name,
        value: company.id,
        title: company.name,
        key: company.id,
      }));
      const childrenDepartmentData = departmentData?.map((department) => ({
        label: department.name,
        value: department.id,
        title: department.name,
        key: department.id,
      }));

      const rootOptionValue2 = 'root2';
      const rootOptionValue3 = 'root3';
      setOptionFilter((prevOptionFilter) => [
        ...prevOptionFilter,
        {
          title: '会社名',
          value: rootOptionValue2,
          key: rootOptionValue2,
          children: childrenCompanyData,
        },
        {
          title: '部署名',
          value: rootOptionValue3,
          key: rootOptionValue3,
          children: childrenDepartmentData,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="fabs-list-wrapper full-width">
      <ComTable
        title={t('user.title')}
        columns={columns}
        loading={loadingTable || loading}
        data={value?.data?.data}
        pagination={paginationProp}
        onAddTable={handleCreate}
        onSearch={handleSearch}
        onChangeTable={handleChangeTable}
        onChangePagination={handleChangePagination}
        isTreeSelect
        valueTreeSelect={valueSelect}
        onChangeTreeSelect={onChangeTreeSelect}
        optionsTreeSelect={optionFilter}
        treeNodeFilterProp="title"
        filterTreeNode={filterTreeNode}
        rowClassName={rowClassName}
        showModal={showModal}
        setIsAddForm={setIsAddForm}
        buttonExport
        dataExport={pagination}
        // onClickRow={onRow}
      />
    </div>
  );
}

UserTables.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
};

export default UserTables;
