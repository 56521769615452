import cache from '@common/utils/cache';
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    company: null,
    user_type_id: null,
    token: cache.get('token'),
    dataLocalstorage: null,
    avatar: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload && action.payload.data.access_token;
      state.user_type_id = action.payload && action.payload.data.user_type_id;
      state.company = action.payload && action.payload.data.company;
      state.dataLocalstorage = action.payload.data;
      cache.set('token', state.token);
      cache.set('refreshToken', action.payload.data.refresh_token);
    },
    logoutSuccess: (state) => {
      state.authUser = null;
      state.token = null;
      cache.remove('token');
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setRole: (state, action) => {
      state.user_type_id = action.payload;
    },
    saveAvatar: (state, action) => {
      state.avatar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, logoutSuccess, setMode, setRole, saveAvatar } =
  slice.actions;

// Get value from state
export const authStore = (state) => state.auth;

export default slice.reducer;
