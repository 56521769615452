/* eslint-disable react/button-has-type */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect } from 'react';
import userApi from '@services/userApi';
import { Form, message, Upload } from 'antd';
import Error from '@common/constant/status';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { UserContext } from '..';
import logo from '../../../../assets/imgs/addition_img.png';

function UploadCsvUser({ isModalOpen, setFileList, fileList, closeModal }) {
  const [form] = Form.useForm();
  const { setLoading } = useContext(UserContext);
  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const handleSubmit = async () => {
    if (!fileList || fileList?.length === 0) {
      message.error('インポートファイルを選択していません。');
      return;
    }
    const formData = new FormData();
    formData.append('file', fileList[0]?.originFileObj);
    try {
      setLoading(true);
      const res = await userApi.importCSV(formData);
      if (res?.statusCode === Error.SUCCESS) {
        window.scrollTo(0, document.body.scrollHeight);
        message.success('CSVファイルのインポートが成功しました。');
        closeModal();
      }
    } catch (error) {
      const errorRes = error?.response?.data;
      if (errorRes) {
        const statusCode = errorRes?.statusCode;
        if (statusCode === Error.BAD_REQUEST) {
          const details = errorRes?.details;
          const messInvalid = details?.message?.[0];
          message.error(messInvalid);
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen === false) {
      setFileList([]);
    }
  }, [isModalOpen]);

  const downloadTemplate = async () => {
    try {
      const fileData = await userApi.downloadTemplate();
      const blob = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'template.xlsx');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };
  return (
    <>
      <div className="right-side-content display-flex jc-center item-center">
        <Upload
          beforeUpload={() => {
            return false;
          }}
          onChange={handleChange}
          fileList={fileList}
        >
          <div className="display-flex jc-center full-width icon-import-wrapper ">
            <img width="60%" height="50%" src={logo} alt="" />
          </div>
        </Upload>
      </div>
      <div className="display-flex jc-space-ard  full-width icon-import-wrapper">
        <div
          onClick={handleSubmit}
          className="save-btn-wrapper save-multiple-btn"
        >
          <button className="csv-btn pointer">
            <span>{t('user.button.addseries')}</span>
          </button>
        </div>
        <div
          onClick={downloadTemplate}
          className="save-btn-wrapper save-multiple-btn"
        >
          <button className="csv-btn pointer">
            <span className="fz-12">
              テンプレートファイル
              <br />
              ダウンロード
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

UploadCsvUser.propTypes = {
  isModalOpen: PropTypes.bool,
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
  closeModal: PropTypes.func,
};

export default UploadCsvUser;
