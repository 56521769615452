/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import ComTable from '@components/common/ComTable';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import companyApi from '@services/company';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_URL } from '@common/config/pathURL';
import STATUSCODE from '@common/constant/status';
import { IconEditBig } from '@assets/icons';
import { useSelector } from 'react-redux';
import { timeDateDisplay } from '@common/utils/dateHelper';
import customTooltip from '@common/utils/customTooltip';
import { CompanyContext } from '..';

function CompanyList({ showModal, setIsAddForm }) {
  const navigate = useNavigate();
  const location = useLocation();
  const stateCompay = useSelector(
    (state) => state?.company?.companyList?.data?.data,
  );
  const { state } = useLocation();
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    keyword: '',
    company_id: location?.state?.company_id,
    type: '',
    order_by: 'id',
  });
  const { showDataCompany, loading, setLoading } = useContext(CompanyContext);
  const { loadingTable, value } = useFetch(
    () => companyApi.getListCompany(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.name,
      pagination.company,
      pagination.page_size,
      pagination.company,
      pagination.order_by,
      pagination.keyword,
      pagination.company_id,
      loading,
      stateCompay,
    ],
  );

  const paginationProp = {
    position: ['bottomRight'],
    total: value?.data?.count,
    pageSize: pagination?.page_size,
    currentPage: pagination?.page_number,
  };
  const checkColumnSorted = pagination?.order;
  const handleSearch = (keyWord) => {
    setPagination({ ...pagination, keyword: keyWord.trim(), page_number: 1 });
  };

  const onChangeSelect = (companyData) => {
    setPagination({ ...pagination, company_id: companyData });
  };

  const handleChangePagination = (currentPage, pageSize) => {
    setPagination((curState) => ({
      ...curState,
      page_size: pageSize,
      page_number: currentPage,
    }));
  };
  const hanldeChangeTable = (_, filter, sorter) => {
    if (sorter?.order === 'descend') {
      setPagination({ ...pagination, order: 'DESC', order_by: sorter?.field });
    } else {
      setPagination({ ...pagination, order: 'ASC', order_by: sorter?.field });
    }
  };
  const showDetail = async (record) => {
    setIsAddForm(false);
    try {
      const res = await companyApi.getDetailCompany(record?.id);
      const statusRes = res?.statusCode;
      if (statusRes === STATUSCODE.SUCCESS) {
        showDataCompany({
          ...res?.data,
          password: '',
          avatar: res.data.avatar
            ? [
                {
                  uid: 1,
                  name: ' ',
                  status: 'done',
                  url: res.data.avatar,
                },
              ]
            : [],
        });
        showModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: t('dashboard.tab2.form.companyName'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 369,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.numberUsers'),
      key: 'user_count',
      sorter: true,
      dataIndex: 'user_count',
      width: 164,
      sortDirections: ['ascend', 'descend', 'ascend'],
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate(PATH_URL.MANAGER_USERS.INDEX, {
              state: {
                company_id: record?.id,
                companyName: record?.name,
              },
            });
          },
          style: { cursor: 'pointer' },
        };
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'user_count')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.numberDeployments'),
      key: 'department_count',
      sorter: true,
      width: 164,
      dataIndex: 'department_count',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'department_count')}
          </div>
        ),
      },
    },
    {
      title: t('dashboard.tab2.form.dateCreate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 162,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return <div>{timeDateDisplay(values)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 88,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            showDetail(record);
          },
        };
      },
    },
  ];

  return (
    <div className="company-list-wrapper">
      <ComTable
        title={
          state?.companyName
            ? `${'会社'} ${'-'} ${state?.companyName}`
            : `${'会社'}`
        }
        loading={loadingTable}
        columns={columns}
        data={value?.data?.data}
        pagination={paginationProp}
        onSearch={handleSearch}
        onChangeSelect={onChangeSelect}
        onChangeTable={hanldeChangeTable}
        onChangePagination={handleChangePagination}
        showModal={showModal}
        setIsAddForm={setIsAddForm}
        chilDren="department"
      />
    </div>
  );
}

CompanyList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
};

export default CompanyList;
