import LayoutComp from '@components/layout';
import LoadingView from '@components/LoadingView';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import routes from '@common/config/routers';
import { useSelector } from 'react-redux';
import { authStore } from './store/slices/authSlice';

function PrivateRoute({ user, role }) {
  if (!user && role === 2) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
}

function App() {
  const { user_type_id } = useSelector(authStore);
  const generateRoutes = () => {
    let result = null;
    result = routes.map((route) => {
      return route.isLayout ? (
        <Route
          key={route.key}
          element={<PrivateRoute user={route?.isGuest} role={user_type_id} />}
        >
          <Route
            key={route.key}
            path={route.path}
            element={<LayoutComp>{route.element}</LayoutComp>}
          >
            {route.children &&
              route.children.map((r) => (
                <Route key={r.key} path={r.path} element={r.element} />
              ))}
          </Route>
        </Route>
      ) : (
        <Route key={route.key} path={route.path} element={route.element} />
      );
    });
    return result;
  };

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Suspense fallback={<LoadingView />}>
        <Router>
          <Routes>
            {generateRoutes()}
            <Route path="/*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}
PrivateRoute.propTypes = {
  user: PropTypes.bool,
  role: PropTypes.number,
};
export default App;
