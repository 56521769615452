/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import FabsForm from './Components/FabsForm';
import FabsList from './Components/FabsList';

export const FabContext = createContext();
function ManageFabs() {
  const { state } = useLocation();
  const { token } = useSelector(authStore);
  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [fab, setFab] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataEmpty = [];
  const defaultLayout = {
    value: Number(state?.layout_id),
  };
  const showDataFab = (dataFab) => {
    setFab(dataFab);
  };
  const [isActive, setIsActive] = useState(true);

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      <div>
        <Modal
          className="modal-timeout"
          okText="はい"
          open={!isActive}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p className="text-center">タイムアウトしました。</p>
          <p className="text-center">再度ログインしてください。</p>
        </Modal>
      </div>

      <FabContext.Provider value={{ fab, showDataFab, loading, setLoading }}>
        <div className="display-flex flex-column pl-100 pr-100 pb-100 full-height color-page">
          <Modal
            title={isAddForm ? 'Fab追加' : 'Fab変更'}
            open={isModalOpen}
            onCancel={closeModal}
            className="modal-add"
            footer={null}
          >
            <FabsForm
              closeModal={closeModal}
              isAddForm={isAddForm}
              initialValues={isAddForm ? dataEmpty : fab}
              defaultLayout={defaultLayout}
            />
          </Modal>
          <FabsList showModal={showModal} setIsAddForm={setIsAddForm} />
        </div>
      </FabContext.Provider>
    </>
  );
}

ManageFabs.propTypes = {};

export default ManageFabs;
