import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import layoutApi from '@services/layout';

export const LayoutGetList = createAsyncThunk(
  'layout/layoutgetAllAction',
  async (data) => {
    const listlayout = await layoutApi.getListLayout(data);
    return listlayout;
  },
);

const layoutSlide = createSlice({
  name: 'layoutSlide',
  initialState: {
    layoutList: [],
    loadinglayout: false,
    filter: null,
    error: '',
  },
  reducers: {},
  extraReducers: {
    [LayoutGetList.pending]: (state, action) => {
      state.loadinglayout = true;
    },
    [LayoutGetList.rejected]: (state, action) => {
      state.loadinglayout = false;
    },

    [LayoutGetList.fulfilled]: (state, action) => {
      state.loadinglayout = false;

      state.layoutList = action.payload;
    },
  },
});
// export layoutslide.reducer;
const { reducer: layoutReducer } = layoutSlide;
export default layoutReducer;
