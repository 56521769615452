/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import userApi from '@services/userApi';
import { IconEditSmall } from '@assets/icons';
import PropTypes from 'prop-types';
import STATUSCODE from '@common/constant/status';
import { useDispatch } from 'react-redux';
import { saveAvatar } from '@store/slices/authSlice';
import { AccountContext } from '.';

function Account({ showModal }) {
  const dispatch = useDispatch();
  const [dataAccount, setDataAccount] = useState();
  const { showDataAccount, loading } = useContext(AccountContext);

  const getAccount = async () => {
    const data = await userApi.getMe();
    setDataAccount(data?.data);
  };
  const getDataAccount = async () => {
    const res = await userApi.getMe();
    const statusRes = res?.statusCode;
    if (statusRes === STATUSCODE.SUCCESS) {
      showDataAccount({
        ...res?.data,
        avatar: res.data.avatar
          ? [
              {
                uid: 1,
                name: ' ',
                status: 'done',
                url: res.data.avatar,
              },
            ]
          : [],
      });
      showModal(true);
    }
  };
  useEffect(() => {
    getAccount();
  }, [loading]);
  useEffect(() => {
    if (dataAccount) {
      dispatch(saveAvatar(dataAccount?.avatar));
    }
  }, [dataAccount]);

  return (
    <div className="display-flex flex-column pl-100 pr-75 pb-100 pt-35 color-page full-height">
      <table className="table-account">
        <tbody>
          <tr>
            <th className="head-table">アカウント</th>
            <th className="btn-edit-account pointer">
              <IconEditSmall onClick={() => getDataAccount()} />
            </th>
          </tr>
          <tr>
            <td className="label">画像</td>
            <td>
              <img src={dataAccount?.avatar} width={350} height={200} alt="" />
            </td>
          </tr>
          <tr>
            <td className="label">メールアドレス</td>
            <td>{dataAccount?.user_id}</td>
          </tr>
          <tr>
            <td className="label">ユーザー名</td>
            <td>{dataAccount?.username}</td>
          </tr>

          <tr>
            <td className="label">会社</td>
            <td>{dataAccount?.company?.name}</td>
          </tr>
          <tr>
            <td className="label">所属</td>
            <td>{dataAccount?.department?.name}</td>
          </tr>
          <tr>
            <td className="label">イニシャル</td>
            <td>{dataAccount?.initial}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
Account.propTypes = {
  showModal: PropTypes.func,
};
export default Account;
