import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { PATH_URL } from '@common/config/pathURL';
import {
  IconAccount,
  IconClose,
  IconCompany,
  IconFab,
  IconLayout,
  IconObject,
  IconOpen,
  IconUser,
  Iconlicense,
} from '@assets/icons';
import RESIZE from '@common/constant/resize';
import { useSelector } from 'react-redux';
import { authStore } from '@store/slices/authSlice';

function getItem(labelText, key, icon, path) {
  const label = (
    <Link to={path} className="fw-500 fz-14 pt-10">
      {labelText}
    </Link>
  );

  return {
    key,
    icon,
    label,
  };
}

const itemsActive = {
  dashboard: '1',
  'manager-account': '2',
  'manager-users': '3',
  'manager-company': '4',
  'manager-department': '4',
  'manager-fabs': '5',
  'manager-layout': '5',
  'manager-objects': '6',
  'manager-license': '7',
  'manager-course': '8',
  'management-checkpoints': '9',
};

function Sider({ collapsed, setCollapsed }) {
  const { pathname } = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const page = pathname.split('/')[1];
  const { dataLocalstorage } = useSelector(authStore);
  const handleToggleCollapse = () => {
    setCollapsed((curentState) => !curentState);
  };

  const reSizePage = () => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    if (windowWidth <= RESIZE) {
      setCollapsed(true);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  };

  useEffect(() => {
    reSizePage();
  }, [windowWidth]);

  const items = [
    getItem(
      t('layout.sider.manager-account'),
      '2',
      <IconAccount />,
      PATH_URL.ACCOUNT.INDEX,
    ),
    getItem(
      t('layout.sider.manager-user'),
      '3',
      <IconUser />,
      PATH_URL.MANAGER_USERS.INDEX,
    ),
    getItem(
      t('layout.sider.manager-company'),
      '4',
      <IconCompany />,
      PATH_URL.MANAGER_COMPANY.INDEX,
    ),
    getItem(
      t('layout.sider.manager-fabs'),
      '5',
      <IconLayout />,
      PATH_URL.MANAGER_LAYOUT.INDEX,
    ),
    getItem(
      t('layout.sider.manager-objects'),
      '6',
      <IconObject />,
      PATH_URL.MANAGER_OBJECTS.INDEX,
    ),
    getItem(
      t('layout.sider.manager-license'),
      '7',
      <Iconlicense />,
      PATH_URL.LICENSE.INDEX,
    ),
  ];
  const items2 = [
    getItem(
      t('layout.sider.manager-account'),
      '2',
      <IconAccount />,
      PATH_URL.ACCOUNT.INDEX,
    ),

    getItem(
      t('layout.sider.manager-license'),
      '7',
      <Iconlicense />,
      PATH_URL.LICENSE.INDEX,
    ),
  ];
  return (
    <Layout.Sider
      breakpoint="lg"
      className="layout-sider"
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={260}
    >
      <Button
        type="text"
        className="btn-collapse"
        onClick={() => handleToggleCollapse()}
      >
        {collapsed ? (
          <IconOpen height="100%" />
        ) : (
          <IconClose className="icon-close" height="100%" />
        )}
      </Button>

      <Menu
        selectedKeys={itemsActive[page]}
        mode="inline"
        items={dataLocalstorage?.user_type_id === 2 ? items2 : items}
      />
    </Layout.Sider>
  );
}

Sider.propTypes = {
  collapsed: Proptypes.bool,
  setCollapsed: Proptypes.func,
};

export default Sider;
