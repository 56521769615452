export const PATH_URL = {
  LOGIN: '/',
  MANAGER_LAYOUT: {
    INDEX: '/manager-layout',
    TABLE: '',
    FORM: 'form',
  },
  MANAGER_FABS: {
    INDEX: '/manager-fabs',
    TABLE: '',
    FORM: 'form',
  },
  MANAGER_USERS: {
    INDEX: '/manager-users',
    TABLE: '',
    FORM: 'form',
  },
  MANAGER_OBJECTS: {
    INDEX: '/manager-objects',
    TABLE: '',
    FORM: 'form',
  },
  MANAGER_COMPANY: {
    INDEX: '/manager-company',
    TABLE: '',
    FORM: 'form',
  },
  MANAGER_DEPARTMENT: {
    INDEX: '/manager-department',
    TABLE: '',
    FORM: 'form',
  },
  COMPONENTS: {
    INDEX: '/manager-components',
    TABLE: '',
    FORM: 'form',
  },
  ACCOUNT: {
    INDEX: '/manager-account',
    TABLE: '',
    FORM: 'form',
  },
  LICENSE: {
    INDEX: '/manager-license',
    TABLE: '',
    FORM: 'form',
  },
};
export default PATH_URL;
