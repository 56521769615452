/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { UserContext } from '..';
import UploadCsvUser from './AddMultipleUser';
import UserForm from './UserForm';

function AddUserForm({
  closeModal,
  isAddForm,
  isModalOpen,
  setFileList,
  fileList,
}) {
  const { listUser } = useContext(UserContext);
  const dataEmpty = [];

  return (
    <div className="add-user-form">
      <Row gutter={40} style={{ backgroundColor: 'white' }}>
        {isAddForm ? (
          <>
            <Col span={12}>
              <UserForm
                initialValues={isAddForm ? dataEmpty : listUser}
                closeModal={closeModal}
                isAddForm={isAddForm}
              />
            </Col>
            <Col span={12}>
              <div className="right-side">
                <UploadCsvUser
                  isAddForm={isAddForm}
                  isModalOpen={isModalOpen}
                  setFileList={setFileList}
                  fileList={fileList}
                  closeModal={closeModal}
                />
              </div>
            </Col>
          </>
        ) : (
          <Col className="edit-user-form" span={24}>
            <UserForm
              initialValues={isAddForm ? dataEmpty : listUser}
              closeModal={closeModal}
              isAddForm={isAddForm}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

AddUserForm.propTypes = {
  closeModal: PropTypes.func,
  isAddForm: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
};

export default AddUserForm;
