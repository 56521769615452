import api from './baseApi';

export const fabApi = {
  getListFabs(params) {
    return api.get('/v1/manage-admin/fab', { params });
  },
  getDetailFabs(params) {
    return api.get(`/v1/manage-admin/fab/${params}`);
  },
  postNewFabs(params) {
    return api.post('/v1/manage-admin/fab', params);
  },
  editFab(id, params) {
    return api.put(`/v1/manage-admin/fab/${id}`, params);
  },
  postNewDataFabs(params) {
    return api.post(`/v1/manage-admin/fab/${params.fab_id}/data`, params);
  },
  deleteFab(id) {
    return api.delete(`/v1/manage-admin/fab/${id}`);
  },
};

export const a = () => null;
