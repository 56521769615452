import { Col, Form, Row } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import TextField from './ComTextField';

function ComTextFieldGroup({ label, name, rules, form, validateY, isObject }) {
  return (
    <Form.Item
      label={label}
      labelAlign="left"
      labelCol={isObject ? { span: 3 } : { span: 5 }}
      className="form-group"
    >
      <Row>
        <Col span={8} className="pl-40">
          <TextField
            label="X"
            name={`${name}_x`}
            rules={rules}
            labelCol={{ span: 5 }}
            labelAlign="left"
          />
        </Col>
        <Col span={8} className="pl-40">
          <TextField
            label="Y"
            name={`${name}_y`}
            rules={
              validateY
                ? [
                    {
                      validator(rule, value, callback) {
                        const pos_y_min = form.getFieldValue('pos_y_min');
                        const pos_y_max = form.getFieldValue('pos_y_max');
                        const val = value?.toString()?.trim();
                        if (!val) {
                          callback('初期座標は必須入力です。');
                        }
                        if (val && !validator.isFloat(val)) {
                          callback('正しいフォーマットで入力してください。');
                        }
                        if (parseFloat(pos_y_max) <= val) {
                          callback(
                            'プレイヤー生成座標Yより上下方向制限の最大より小さいです。',
                          );
                        }
                        if (parseFloat(pos_y_min) >= val) {
                          callback(
                            'プレイヤー生成座標Yより上下方向制限の最小より大きいです。',
                          );
                        } else {
                          callback();
                        }
                      },
                    },
                  ]
                : rules
            }
            labelCol={{ span: 5 }}
            labelAlign="left"
          />
        </Col>
        <Col span={8} className="pl-40">
          <TextField
            label="Z"
            name={`${name}_z`}
            rules={rules}
            labelCol={{ span: 5 }}
            labelAlign="left"
          />
        </Col>
      </Row>
    </Form.Item>
  );
}

ComTextFieldGroup.propTypes = {
  label: PropTypes.node,
  rules: PropTypes.array,
  name: PropTypes.string,
  form: PropTypes.object,
  validateY: PropTypes.string,
  isObject: PropTypes.bool,
};

export default ComTextFieldGroup;
