import React from 'react';
import Login from '@pages/login';

import ManageFabs from '@pages/manager/Fabs';
import ManageUsers from '@pages/manager/Users';
import Account from '@pages/manager/Account/Account';
import ManageObjects from '@pages/manager/Objects';
import ManageCompany from '@pages/manager/Company';
import ManageDepartment from '@pages/manager/Department';
// import ManageLayout from '@pages/manager/Layout';
import ManageLicense from '@pages/manager/License';
import ManageLayoutCustom from '@pages/manager/CompanyCustom';
import ManageAccount from '@pages/manager/Account';
import { PATH_URL } from './pathURL';

const routes = [
  {
    key: 1,
    path: PATH_URL.LOGIN,
    element: <Login />,
  },
  {
    key: 2,
    path: PATH_URL.MANAGER_USERS.INDEX,
    element: <ManageUsers />,
    isLayout: true,
  },
  {
    key: 3,
    path: PATH_URL.MANAGER_COMPANY.INDEX,
    element: <ManageCompany />,
    isLayout: true,
  },
  {
    key: 4,
    path: PATH_URL.MANAGER_LAYOUT.INDEX,
    element: <ManageLayoutCustom />,
    isLayout: true,
  },
  {
    key: 5,
    path: PATH_URL.ACCOUNT.INDEX,
    element: <ManageAccount />,
    isLayout: true,
    isGuest: true,
  },
  {
    key: 6,
    path: PATH_URL.MANAGER_OBJECTS.INDEX,
    element: <ManageObjects />,
    isLayout: true,
  },
  {
    key: 7,
    path: PATH_URL.MANAGER_DEPARTMENT.INDEX,
    element: <ManageDepartment />,
    isLayout: true,
  },
  {
    key: 8,
    path: PATH_URL.MANAGER_FABS.INDEX,
    element: <ManageFabs />,
    isLayout: true,
  },
  {
    key: 9,
    path: PATH_URL.LICENSE.INDEX,
    element: <ManageLicense />,
    isLayout: true,
    isGuest: true,
  },
];

export default routes;
