/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import TextField from '@components/common/ComTextField';
import { Card, ConfigProvider, Form, message, Modal } from 'antd';
import localeValues from 'antd/lib/locale/ja_JP';
import { validateMax } from '@common/utils/validate';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import companyApi from '@services/company';
import STATUSCODE from '@common/constant/status';
import ComButton from '@components/common/ComButton';
import userApi from '@services/userApi';
import { CompanyContext } from '..';

function CompanyForm({ initialValues, closeModal }) {
  const [form] = Form.useForm();
  const { setLoading, loading } = useContext(CompanyContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [listUser, setListUser] = useState();

  const checkUserInCompany = listUser?.filter(
    (x) => x?.company_id === initialValues?.id,
  );

  const addCompany = async (values) => {
    try {
      setLoading(true);
      const res = await companyApi.postCompany(values);
      if (res?.statusCode === STATUSCODE.SUCCESS) {
        message.success(t('company.message.addSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === STATUSCODE.UNPROCESSABLE) {
        message.error(t('company.message.error422'));
      }
      if (err?.response?.data?.status === STATUSCODE.SERVER_ERROR) {
        message.error(t('company.message.addFail'));
      }
    }
  };
  const editCompany = async (values) => {
    try {
      setLoading(true);
      const res = await companyApi.editCompany(initialValues?.id, {
        ...values,
        conpany_id: initialValues?.id,
      });
      if (res?.statusCode === STATUSCODE.SUCCESS) {
        message.success(t('company.message.editSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === STATUSCODE.UNPROCESSABLE) {
        message.error(t('company.message.editFail'));
      }
    }
  };

  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (!isEditMode) {
      addCompany(trimmedValues);
    } else {
      editCompany(trimmedValues);
    }
  };

  const handleDelete = async (id) => {
    if (checkUserInCompany?.length) {
      message.error('所属しているユーザーが存在するため削除できません。');
    } else {
      try {
        setLoading(true);
        const res = await companyApi.deleteCompany(id);
        if (res.statusCode === STATUSCODE.SUCCESS) {
          message.success('会社の削除に成功しました。');
          closeModal();
        }
      } catch (error) {
        if (error.response.status === STATUSCODE.BAD_REQUEST) {
          message.error('会社の削除に失敗しました。');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択した会社を削除しますか？削除した会社はもとには戻せません。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  useEffect(() => {
    if (!isEditMode) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, isEditMode]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues).length);
  }, [initialValues]);
  const getAllUsers = async () => {
    const res = await userApi.getListUser();
    setListUser(res?.data?.data);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <ConfigProvider locale={localeValues}>
      <Card className="add-company-form">
        <Form
          name="tab2-form"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          colon={false}
          className="user-form"
          validateTrigger="onChange"
        >
          <TextField
            {...configTextField}
            label={t('dashboard.tab2.form.companyName')}
            name="name"
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    30,
                    t('dashboard.tab2.form.companyName'),
                  ),
              },
            ]}
          />
          <div className="full-width button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              {isEditMode ? '変更' : '追加'}
            </ComButton>
            {isEditMode !== 0 && (
              <ComButton
                onClick={() => confirmDelete()}
                className="save-btn btn-delete"
              >
                削除
              </ComButton>
            )}
          </div>
        </Form>
      </Card>
    </ConfigProvider>
  );
}
CompanyForm.propTypes = {
  initialValues: PropTypes.any,
  isAddForm: PropTypes.bool,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CompanyForm;
