/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import ObjectsList from './Components/ObjectList';
import ObjectsForm from './Components/ObjectForm';

export const ObjectContext = createContext();
function ManageObjects() {
  const { token } = useSelector(authStore);
  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [listObjects, setListObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataEmpty = [];
  const showDataObject = (dataObject) => {
    setListObject(dataObject);
  };

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      <div>
        <Modal
          className="modal-timeout"
          okText="はい"
          open={!isActive}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p className="text-center">タイムアウトしました。</p>
          <p className="text-center">再度ログインしてください。</p>
        </Modal>
      </div>

      <ObjectContext.Provider
        value={{ listObjects, showDataObject, loading, setLoading }}
      >
        <div className="display-flex flex-column pl-100 full-height pr-100 pb-100 color-page">
          <Modal
            title={
              isAddForm
                ? 'オブジェクトテンプレート追加'
                : 'オブジェクトテンプレート編集'
            }
            open={isModalOpen}
            onCancel={closeModal}
            className="modal-add"
            footer={null}
          >
            <ObjectsForm
              closeModal={closeModal}
              isAddForm={isAddForm}
              initialValues={isAddForm ? dataEmpty : listObjects}
              isModalOpen={isModalOpen}
            />
          </Modal>
          <ObjectsList showModal={showModal} setIsAddForm={setIsAddForm} i />
        </div>
      </ObjectContext.Provider>
    </>
  );
}

ManageObjects.propTypes = {};

export default ManageObjects;
