/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import { Button, Form, message, Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

export default function ComUploadFile({
  length = 1,
  name,
  className = '',
  label,
  fileList,
  setFileList,
  rules,
  isFab,
  isObject,
}) {
  const uploadRef = useRef();
  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
  };
  const beforeUpload = (file) => {
    if (!isFab) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['glb'];
      const isAllowedExtension = allowedExtensions.includes(fileExtension);

      if (!isAllowedExtension) {
        message.error('データにはGLBファイルをアップロードしてください。');
      }

      return isAllowedExtension;
    }

    return true;
  };
  const getFile = (e) => {
    const isValid = e.file.status === 'removed' ? true : beforeUpload(e.file);

    if (isValid) {
      setFileList(e?.fileList);
    }
  };

  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 10 },
      xl: { span: 7 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  return (
    <div className="position-relative">
      <Form.Item
        {...configTextField}
        name={name}
        getValueFromEvent={getFile}
        rules={rules}
        label={label}
      >
        <Upload
          ref={uploadRef}
          name={`child_${name}`}
          className={`upload ${className}`}
          listType="picture-card"
          maxCount={1}
          fileList={fileList}
          onPreview={() => {}}
          beforeUpload={() => false}
          onRemove={handleRemove}
        >
          {fileList.length < length && (
            <div className="text-upload">未選択</div>
          )}
        </Upload>
      </Form.Item>
    </div>
  );
}
ComUploadFile.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  length: PropTypes.number,
  label: PropTypes.string,
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
  rules: PropTypes.array,
  isFab: PropTypes.bool,
  isObject: PropTypes.bool,
};
