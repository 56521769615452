const ROLE_ID = {
  admin: 1,
  user: 0,
};
export default ROLE_ID;

export const OPTION_FILTER_USER = [
  {
    title: 'ROLE',
    value: '0',
    children: [
      {
        title: 'ADMIN',
        value: 'admin',
      },
      {
        title: 'USER',
        value: 'user',
      },
    ],
  },
  {
    title: 'COMPANY_NAME',
    value: '1',
    children: [
      {
        title: 'abc',
        value: 'abc',
      },
      {
        title: 'xyz',
        value: 'xyz',
      },
    ],
  },
];
