import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { IconDropdown2 } from '@assets/icons';

function SelectField({ label, name, options, disabled, ...props }) {
  return (
    <Form.Item label={label} name={name} {...props}>
      <Select
        suffixIcon={<IconDropdown2 width={25} height={25} />}
        options={options}
        allowClear
        className="btn-allowclear"
        disabled={disabled}
      >
        SelectField
      </Select>
    </Form.Item>
  );
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

export default SelectField;
