/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import useFetch from '@common/hooks/useFetch';
import ComTable from '@components/common/ComTable';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import objectApi from '@services/object';
import { IconEditBig } from '@assets/icons';
import { timeDateDisplay } from '@common/utils/dateHelper';
import customTooltip from '@common/utils/customTooltip';
import { ObjectContext } from '..';

function ObjectsList({ showModal, setIsAddForm }) {
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    room_name: '',
    company_ids: '',
    order_by: 'id',
    keyword: '',
    object_type_ids: '',
  });
  const [optionFilter, setOptionFilter] = useState([]);
  const [listType, setListType] = useState();
  const { showDataObject, loading, setLoading } = useContext(ObjectContext);

  const { loadingTable, value } = useFetch(
    () => objectApi.getListObjectTemplate(pagination),
    [
      pagination.page_number,
      pagination.order,
      pagination.room_name,
      pagination.company_ids,
      pagination.page_size,
      pagination.company,
      pagination.order_by,
      pagination.keyword,
      pagination.object_type_ids,
      loading,
    ],
  );

  const totalPage = value?.data?.count;
  const paginationProp = {
    position: ['bottomRight'],
    total: totalPage,
    pageSize: pagination?.page_size,
    currentPage: pagination?.page_number,
  };

  const handleSearch = (values) => {
    setPagination({
      ...pagination,
      keyword: values.trim(),
      page_number: 1,
    });
  };

  const onChangeTreeSelect = (dataSelect) => {
    const filterOption = listType?.filter((item) =>
      dataSelect.includes(item?.id),
    );
    const paramType = filterOption.map((x) => x.id).join(',');
    setPagination({ ...pagination, object_type_ids: paramType });
  };
  const handleChangePagination = (currentPage, pageSize) => {
    setPagination((curState) => ({
      ...curState,
      page_size: pageSize,
      page_number: currentPage,
    }));
  };

  const hanldeChangeTable = (_, filter, sorter) => {
    const sortField = sorter?.field;
    const checkSortField = {
      objectType: 'object_type_name',
    };
    if (sorter?.order === 'descend') {
      setPagination({
        ...pagination,
        order: 'DESC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    } else {
      setPagination({
        ...pagination,
        order: 'ASC',
        order_by: checkSortField[sortField]
          ? checkSortField[sortField]
          : sortField,
      });
    }
  };
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'Script error.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  const showDetail = async (values) => {
    setIsAddForm(false);
    try {
      const res = await objectApi.getDetailObjectTemplate(values?.id);
      showDataObject({
        ...res?.data,
      });
      showModal();
    } catch (error) {
      console.log('err');
    }
  };
  const checkColumnSorted = pagination.order;

  const columns = [
    {
      title: t('object.table.objectName'),
      dataIndex: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      key: 'name',
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'name')}
          </div>
        ),
      },
    },
    {
      title: t('object.table.typeObject'),
      dataIndex: 'objectType',
      key: 'objectType',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return <div>{values?.name}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'object_type_name')}
          </div>
        ),
      },
    },
    {
      title: t('object.table.dateObject'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (values) => {
        return <div>{timeDateDisplay(values)}</div>;
      },
      showSorterTooltip: {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            {customTooltip(pagination, checkColumnSorted, 'createdAt')}
          </div>
        ),
      },
    },
    {
      title: '編集',
      width: 40,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: () => {
        return (
          <div className="display-flex jc-center pointer">
            <IconEditBig />
          </div>
        );
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            showDetail(record);
          },
        };
      },
    },
  ];

  const fetchListType = async () => {
    try {
      const res = await objectApi.getListObjectType();
      setListType(res.data);
      const TypeData = res.data.map((Type) => ({
        id: Type?.id,
        name: Type?.name,
      }));
      const uniqueObjects = TypeData.filter((data, index, self) => {
        const currentName = data.name;
        return self.findIndex((obj) => obj.name === currentName) === index;
      });
      const childrenTypeData = uniqueObjects.map((Type) => ({
        label: Type.name,
        value: Type.id,
        title: Type.name,
      }));
      setOptionFilter((prevOptionFilter) => [
        ...prevOptionFilter,
        {
          title: 'タイプ',
          value: '0',
          key: '0',
          children: childrenTypeData,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const filterTreeNode = (inputValue, treeNode) => {
    const title = treeNode?.props?.title.trim() || '';
    return title.toLowerCase().includes(inputValue.toLowerCase().trim());
  };
  useEffect(() => {
    fetchListType();
  }, []);

  return (
    <div className="object-list-wrapper">
      <ComTable
        title="オブジェクトテンプレート"
        loading={loadingTable || loading}
        columns={columns}
        data={value?.data?.data}
        pagination={paginationProp}
        isTreeSelect
        onSearch={handleSearch}
        onChangeTable={hanldeChangeTable}
        onChangePagination={handleChangePagination}
        showModal={showModal}
        setIsAddForm={setIsAddForm}
        onChangeTreeSelect={onChangeTreeSelect}
        optionsTreeSelect={optionFilter}
        treeNodeFilterProp="title"
        filterTreeNode={filterTreeNode}
      />
    </div>
  );
}

ObjectsList.propTypes = {
  showModal: PropTypes.func,
  setIsAddForm: PropTypes.func,
};

export default ObjectsList;
