import api from './baseApi';

const companyApi = {
  getListCompany: (params) => {
    return api.get('/v1/manage-admin/company', { params });
  },
  getDetailCompany: (id) => {
    return api.get(`/v1/manage-admin/company/${id}`);
  },
  getListDropdown: (params) => {
    return api.get('/v1/manage-admin/company/dropdown', { params });
  },
  postCompany: (params) => {
    return api.post('/v1/manage-admin/company', params);
  },
  editCompany: (id, params) => {
    return api.put(`/v1/manage-admin/company/${id}`, params);
  },
  deleteCompany: (id) => {
    return api.delete(`/v1/manage-admin/company/${id}`);
  },
};

export default companyApi;
