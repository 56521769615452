import { CLIENT_ID, TENANT_ID } from '@common/config/endpoint';

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID || '',
    redirectUri: window.location.origin,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
