const eulerToQuaternion = (rosX, rosY, rosZ) => {
  const radianX = (rosX * Math.PI) / 180;
  const radianY = (rosY * Math.PI) / 180;
  const radianZ = (rosZ * Math.PI) / 180;

  const c1 = Math.cos(radianX / 2);
  const c2 = Math.cos(radianY / 2);
  const c3 = Math.cos(radianZ / 2);
  const s1 = Math.sin(radianX / 2);
  const s2 = Math.sin(radianY / 2);
  const s3 = Math.sin(radianZ / 2);

  const w = c1 * c2 * c3 - s1 * s2 * s3;
  const y = c1 * s2 * c3 - s1 * c2 * s3;
  const x = s1 * c2 * c3 + c1 * s2 * s3;
  const z = s1 * s2 * c3 + c1 * c2 * s3;

  return { rotation_w: w, rotation_x: x, rotation_y: y, rotation_z: z };
};
export default eulerToQuaternion;
