/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Canvas, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Html, OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three';

function BackgroundImage({ texture }) {
  const hidden = true;
  return (
    <mesh rotation={[-1.5, 0, 0]}>
      <planeGeometry args={[15, 15]} />
      <meshBasicMaterial attach="material" map={texture} depthTest={hidden} />
    </mesh>
  );
}
BackgroundImage.propTypes = {
  texture: PropTypes.string,
};
function Keen(props) {
  const { scene } = useLoader(GLTFLoader, props?.modal3DFile);
  props?.setIsLoading(scene);
  return (
    <primitive
      className="display-flex jc-center"
      object={props?.modal3DFile && scene}
      dispose={null}
      {...props}
      scale={[1.5, 1, 1]}
    />
  );
}
Keen.propTypes = {
  modal3DFile: PropTypes.string,
  setIsLoading: PropTypes.func,
};

function Model3d({ modal3DFile, dataPosition, dataRotation }) {
  const [widthModel, setWidthModel] = useState('40%');
  const [isLoading, setIsLoading] = useState();
  useEffect(() => {
    setWidthModel('40%');
  }, [isLoading]);
  const texture = useLoader(TextureLoader, '/background.png');

  return (
    <Canvas
      className="display-flex item-center model"
      style={{
        height: '250px',
        width: widthModel,
        // justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
      invalidateFrameloop
      resize={{ scroll: true, debounce: { scroll: 0, resize: 50 } }}
      camera={{ position: [0, 1, 2], fov: 90 }}
    >
      <ambientLight />
      <BackgroundImage texture={texture} position={(-2.5, -1.5, 1.5)} />
      <Suspense
        fallback={
          <Html style={{ color: 'white' }} center>
            Loading...
          </Html>
        }
      >
        <Keen
          modal3DFile={modal3DFile}
          position={dataPosition}
          rotation={dataRotation}
          setIsLoading={setIsLoading}
        />
      </Suspense>

      <OrbitControls
        // enableRotate
        // enablePan={false}
        // enableZoom={false}
        maxPolarAngle={(2 * Math.PI) / 5}
      />
    </Canvas>
  );
}
Model3d.propTypes = {
  modal3DFile: PropTypes.string,
  dataPosition: PropTypes.array,
  dataRotation: PropTypes.array,
};

export default Model3d;
