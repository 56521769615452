/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import TextField from '@components/common/ComTextField';
import { Card, ConfigProvider, Form, message, Modal, Typography } from 'antd';
import localeValues from 'antd/lib/locale/ja_JP';
import { validateMax } from '@common/utils/validate';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import departmentApi from '@services/department';
import STATUSCODE from '@common/constant/status';
import ComButton from '@components/common/ComButton';
import { useDispatch } from 'react-redux';
import { companyGetList } from '@store/slices/companySlice';
import { DepartmentContext } from '..';

function DepartmentForm({ initialValues, closeModal, idCompany }) {
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    keyword: '',
    company_id: idCompany,
    type: '',
    order_by: 'id',
  });
  const [form] = Form.useForm();
  const { setLoading, loading } = useContext(DepartmentContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const dispath = useDispatch();

  const createDepartment = async (values) => {
    try {
      setLoading(true);
      const res = await departmentApi.postDepartment({
        ...values,
        company_id: Number(idCompany),
      });
      if (res?.statusCode === STATUSCODE.SUCCESS) {
        dispath(companyGetList(pagination));
        message.success(t('department.message.addSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === STATUSCODE.UNPROCESSABLE) {
        message.error(t('department.message.error422'));
      }
      if (err?.response?.data?.status === STATUSCODE.SERVER_ERROR) {
        message.error(t('department.message.addFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const editDepartment = async (values) => {
    try {
      setLoading(true);

      const res = await departmentApi.editDepartment(initialValues?.id, {
        ...values,
        company_id: initialValues?.company_id,
      });

      if (res?.statusCode === STATUSCODE.SUCCESS) {
        message.success(t('department.message.editSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.status === STATUSCODE.UNPROCESSABLE) {
        message.error(t('department.message.editFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (!isEditMode) {
      createDepartment(trimmedValues);
    } else {
      editDepartment(trimmedValues);
    }
  };
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await departmentApi.deleteDepartment(id);
      if (res.statusCode === STATUSCODE.SUCCESS) {
        message.success('部署の削除に成功しました。');
        dispath(companyGetList(pagination));
        closeModal();
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error('部署の削除に失敗しました。。');
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択した部署を削除しますか？削除した部署はもとには戻せません。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  useEffect(() => {
    if (!isEditMode) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, isEditMode]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues).length);
  }, [initialValues]);

  return (
    <ConfigProvider locale={localeValues}>
      <Card className="add-company-form">
        <Form
          name="tab2-form"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          colon={false}
          className="user-form"
          validateTrigger="onChange"
        >
          <TextField
            {...configTextField}
            label={t('dashboard.tab2.form.department')}
            name="name"
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    30,
                    t('dashboard.tab2.form.department'),
                  ),
              },
            ]}
          />

          <div className="full-width  button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              {isEditMode ? '変更' : '追加'}
            </ComButton>
            {isEditMode !== 0 && (
              <ComButton
                onClick={() => confirmDelete()}
                className="save-btn btn-delete"
              >
                削除
              </ComButton>
            )}
          </div>
        </Form>
      </Card>
    </ConfigProvider>
  );
}
DepartmentForm.propTypes = {
  initialValues: PropTypes.object,
  isAddForm: PropTypes.bool,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  idCompany: PropTypes.number,
};

export default DepartmentForm;
