/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import TextField from '@components/common/ComTextField';
import { Card, ConfigProvider, Form, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import localeValues from 'antd/lib/locale/ja_JP';
import { validateMax } from '@common/utils/validate';
import STATUSCODE from '@common/constant/status';
import { t } from 'i18next';
import SelectField from '@components/common/ComSelectField';
import companyApi from '@services/company';
import layoutApi from '@services/layout';
import ComButton from '@components/common/ComButton';
import { useDispatch } from 'react-redux';
import { LayoutGetList } from '@store/slices/layoutSlices';
import { LayoutContext } from '..';

function LayoutForm({ closeModal, initialValues }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setLoading, loading } = useContext(LayoutContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [listCompany, setListCompany] = useState();
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 15,
    order: 'DESC',
    keyword: '',
    type: '',
    order_by: 'id',
  });
  const createLayout = async (values) => {
    try {
      setLoading(true);
      const res = await layoutApi.postLayout(values);
      if (res?.statusCode === STATUSCODE.SUCCESS) {
        dispatch(LayoutGetList(pagination));
        message.success(t('layout.message.addSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error(t('layout.message.addFail'));
      }
      closeModal();
      setLoading(false);
    }
  };

  const editLayout = async (values) => {
    try {
      setLoading(true);
      const res = await layoutApi.editlayout(initialValues?.id, {
        ...values,
      });

      if (res?.statusCode === STATUSCODE.SUCCESS) {
        message.success(t('layout.message.editSuccess'));
        form.resetFields();
        setIsEditMode(false);
      }
      closeModal();
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error(t('layout.message.editFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});

    if (!isEditMode) {
      createLayout(trimmedValues);
    } else {
      editLayout(trimmedValues);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await layoutApi.deleteLayout(id);
      if (res.statusCode === STATUSCODE.SUCCESS) {
        message.success('レイアウトの削除に成功しました。');
        closeModal();
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error('レイアウトの削除に失敗しました。');
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択したLayoutを削除しますか？削除したLayoutはもとには戻せません。Layoutに紐づいているFabも削除されます。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  const companyListOpton = useMemo(
    () =>
      listCompany?.map((e) => ({
        label: e.name,
        value: e.id,
        ...e,
      })),
    [listCompany],
  );
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  useEffect(() => {
    if (!isEditMode) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, isEditMode]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues).length);
  }, [initialValues]);
  const fetchDataCompany = async () => {
    try {
      const res = await companyApi.getListCompany();
      setListCompany(res?.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataCompany();
  }, []);
  return (
    <ConfigProvider locale={localeValues}>
      <Card className="add-company-form ">
        <Form
          name="tab2-form"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          colon={false}
          className="user-form"
          validateTrigger="onChange"
        >
          <TextField
            {...configTextField}
            label={t('layout.table.layoutName')}
            name="name"
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    30,
                    t('layout.table.layoutName'),
                  ),
              },
            ]}
          />
          <SelectField
            {...configTextField}
            labelCol={{ span: 12 }}
            label={t('layout.table.companyName')}
            name="company_id"
            options={companyListOpton || []}
            rules={[
              { required: true, message: t('layout.message.requiredCompany') },
            ]}
          />

          <div className="full-width  button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              {isEditMode ? '変更' : '追加'}
            </ComButton>
            {isEditMode !== 0 && (
              <ComButton
                onClick={() => confirmDelete()}
                className="save-btn btn-delete"
              >
                削除
              </ComButton>
            )}
          </div>
        </Form>
      </Card>
    </ConfigProvider>
  );
}
LayoutForm.propTypes = {
  closeModal: PropTypes.func,
  isAddForm: PropTypes.bool,
  initialValues: PropTypes.any,
};
export default LayoutForm;
