import api from './baseApi';

const userApi = {
  getListUser: (params) => {
    return api.get('/v1/manage-admin/user', { params });
  },
  getDetailUser: (id) => {
    return api.get(`/v1/manage-admin/user/${id}`);
  },
  addNewUser: (params) => {
    return api.post('/v1/manage-admin/user', params);
  },
  editUser: (id, params) => {
    return api.put(`/v1/manage-admin/user/${id}`, params);
  },
  deleteUser: (id) => {
    return api.delete(`/v1/manage-admin/user/${id}`);
  },
  importCSV: (params) => {
    return api.post('/v1/manage-admin/user/import', params);
  },
  uploadImage: (id, params) => {
    return api.post(`/v1/manage-admin/user/upload-avatar/${id}`, params);
  },
  lockUser: (id) => {
    return api.get(`/v1/manage-admin/user/lock/${id}`);
  },
  unLockUser: (id) => {
    return api.get(`/v1/manage-admin/user/unlock/${id}`);
  },
  deleteAvatar: (id) => {
    return api.delete(`/v1/manage-admin/user/delete-avartar/${id}`);
  },
  deleteAvatarMe: () => {
    return api.delete('/v1/user/delete-avatar');
  },
  downloadTemplate: () => {
    return api.get(`/v1/user/template`, { responseType: 'blob' });
  },
  getMe() {
    return api.get('/v1/user/me');
  },
  UpdateMe(data) {
    return api.put('/v1/user/me', data);
  },
  uploadAvatar(params) {
    return api.post('/v1/user/upload-avatar', params);
  },
  exportExcel: (params) => {
    return api.get(`/v1/manage-admin/user/export`, {
      params,
      responseType: 'blob',
    });
  },
};

export default userApi;
