/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useState } from 'react';
import { authStore, logoutSuccess } from '@store/slices/authSlice';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import LayoutList from './Components/LayoutList';
import LayoutForm from './Components/LayoutForm';

export const LayoutContext = createContext();
function ManageLayout({ id, dataSearch }) {
  const { token } = useSelector(authStore);
  const tokenData = token && jwt_decode(token);
  const expTime = new Date(tokenData?.exp * 1000);
  const initTime = new Date(tokenData?.iat * 1000);
  const timeOut = expTime?.getTime() - initTime?.getTime();
  const dispatch = useDispatch();
  const [listLayout, setListLayout] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataEmpty = [];
  const showDataLayout = (datalayout) => {
    setListLayout(datalayout);
  };
  const [isActive, setIsActive] = useState(true);

  const handleOk = () => {
    dispatch(logoutSuccess());
  };

  const handleCancel = () => {
    dispatch(logoutSuccess());
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsActive(false), timeOut);
    };

    const onActivity = () => resetTimeout();

    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);

    resetTimeout();

    return () => {
      document.removeEventListener('mousemove', onActivity);
      document.removeEventListener('keydown', onActivity);
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
      <div>
        <Modal
          className="modal-timeout"
          okText="はい"
          open={!isActive}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p className="text-center">タイムアウトしました。</p>
          <p className="text-center">再度ログインしてください。</p>
        </Modal>
      </div>

      <LayoutContext.Provider
        value={{ listLayout, showDataLayout, loading, setLoading }}
      >
        <div className="display-flex flex-column full-height color-page">
          <Modal
            title={isAddForm ? 'レイアウト追加' : 'レイアウト変更'}
            open={isModalOpen}
            onCancel={closeModal}
            className="modal-add"
            footer={null}
          >
            <LayoutForm
              closeModal={closeModal}
              isAddForm={isAddForm}
              initialValues={isAddForm ? dataEmpty : listLayout}
              idCompany={id}
            />
          </Modal>
          <LayoutList
            showModal={showModal}
            setIsAddForm={setIsAddForm}
            company_id={id}
            dataSearch={dataSearch}
          />
        </div>
      </LayoutContext.Provider>
    </>
  );
}

ManageLayout.propTypes = { id: PropTypes.string, dataSearch: PropTypes.object };

export default ManageLayout;
