import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import companyApi from '@services/company';

export const companyGetList = createAsyncThunk(
  'company/companygetAllAction',
  async (data) => {
    const listcompany = await companyApi.getListCompany(data);
    return listcompany;
  },
);

const companySlide = createSlice({
  name: 'companySlide',
  initialState: {
    companyList: [],
    loadingCompany: false,
    filter: null,
    error: '',
  },
  reducers: {},
  extraReducers: {
    [companyGetList.pending]: (state, action) => {
      state.loadingCompany = true;
    },
    [companyGetList.rejected]: (state, action) => {
      state.loadingCompany = false;
    },

    [companyGetList.fulfilled]: (state, action) => {
      state.loadingCompany = false;

      state.companyList = action.payload;
    },
  },
});
// export companyslide.reducer;
const { reducer: companyReducer } = companySlide;
export default companyReducer;
