/* eslint-disable import/order */
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { PATH_URL } from '@common/config/pathURL';
import { authStore } from '@store/slices/authSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Sider from './Sider';

const { Content } = Layout;
function LayoutComp({ children }) {
  const navigate = useNavigate();

  const { token } = useSelector(authStore);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate(PATH_URL.LOGIN);
    }
  }, [token]);
  return (
    <Layout>
      <Header />
      <Layout>
        <Sider collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout className="color-page">
          <Content
            className={`layout-wraper-content ${
              collapsed ? 'collapsed' : 'uncollapsed'
            }`}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

LayoutComp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default LayoutComp;
