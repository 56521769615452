import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@common/constant/date';

export const timeDateDisplay = (value) => {
  return value ? dayjs(value).format(DATE_FORMAT) : '';
};

export const dateTimeFormat = (value) => {
  return value ? dayjs(value).format(DATE_TIME_FORMAT) : '';
};
