/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import TextField from '@components/common/ComTextField';
import {
  Card,
  ConfigProvider,
  Form,
  message,
  Modal,
  Typography,
  Col,
  Space,
} from 'antd';
import localeValues from 'antd/lib/locale/ja_JP';
import { validateFloat, validateMax } from '@common/utils/validate';
import ComButton from '@components/common/ComButton';
import { t } from 'i18next';
import SelectField from '@components/common/ComSelectField';
import PropTypes from 'prop-types';
import STATUSCODE from '@common/constant/status';
import objectApi from '@services/object';
import ComUploadFile from '@components/common/ComUploadFile';
import uploadApi from '@services/uploadApi';
import ComTextFieldGroup from '@components/common/ComTextFieldGroup';
import eulerToQuaternion from '@common/utils/convertQuaternion';
import { ObjectContext } from '..';
import Model3d from './Model3d';

function ObjectsForm({ closeModal, isAddForm, initialValues, isModalOpen }) {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const obj = Form.useWatch('object_link', form);
  const posX = Form.useWatch('position_x', form);
  const posY = Form.useWatch('position_y', form);
  const posZ = Form.useWatch('position_z', form);
  const dataPosition = [posX || 0, posY || 0, posZ || 0];
  const rotX = Form.useWatch('rotation_x', form);
  const rotY = Form.useWatch('rotation_y', form);
  const rotZ = Form.useWatch('rotation_z', form);
  const dataRotation = [
    (Math.PI / 180) * rotX || 0,
    (Math.PI / 180) * rotY || 0,
    (Math.PI / 180) * rotZ || 0,
  ];
  const { listObjects, loading, setLoading } = useContext(ObjectContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileLink, setFileLink] = useState();
  function formatStringToNumber(jsonObj) {
    const formattedObj = { ...jsonObj };
    // eslint-disable-next-line no-restricted-syntax
    for (const key in formattedObj) {
      if (
        key !== 'name' &&
        key !== 'object_type_id' &&
        key !== 'object_link' &&
        typeof formattedObj[key] === 'string'
      ) {
        const parsedNumber = parseFloat(formattedObj[key]);
        // eslint-disable-next-line no-restricted-globals
        formattedObj[key] = isNaN(parsedNumber)
          ? formattedObj[key]
          : parsedNumber;
      }
    }
    return formattedObj;
  }
  const createObject = async (values) => {
    const eulerConvert = eulerToQuaternion(
      values?.rotation_x,
      values?.rotation_y,
      values?.rotation_z,
    );
    const { object_link, ...data } = values;
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});

    try {
      setLoading(true);
      if (fileList?.length) {
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        const res = await uploadApi.uploadFile(formData);
        const resObj = await objectApi.postObjectTemplate({
          ...formatStringToNumber(trimmedValues),
          object_link: res?.data?.url,
          rotation_w: eulerConvert?.rotation_w,
        });
        if (resObj.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('object.message.addSuccess'));
          closeModal();
          setLoading(false);
          setFileList([]);
          setIsEditMode(false);
          form.resetFields();
        }
      } else {
        const res = await objectApi.postObjectTemplate({
          ...formatStringToNumber(data),
          rotation_w: eulerConvert?.rotation_w,
        });
        if (res.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('object.message.addSuccess'));
          closeModal();
          setLoading(false);
          setFileList([]);
          setIsEditMode(false);
          form.resetFields();
        }
      }
    } catch (err) {
      if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error(t('object.message.addFail'));
      }
      closeModal();
      setLoading(false);
    }
  };
  const editObject = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (fileList?.length > 0) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', fileList[0]?.originFileObj);
        const res = await uploadApi.uploadFile(formData);
        const resObj = await objectApi.editObjectTemplate(initialValues?.id, {
          ...formatStringToNumber(trimmedValues),
          object_link: res?.data?.url,
        });
        if (resObj.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('object.message.editSuccess'));
          setLoading(false);
          setIsEditMode(false);
          form.resetFields();
          setFileList([]);
          closeModal();
        }
      } catch (err) {
        if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
          message.error(t('object.message.editFail'));
        }
        setLoading(false);
        closeModal();
      }
    } else if (!obj) {
      try {
        setLoading(true);
        const resObj = await objectApi.editObjectTemplate(initialValues?.id, {
          ...formatStringToNumber(trimmedValues),
          object_link: !obj && null,
        });
        if (resObj.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('object.message.editSuccess'));
          setLoading(false);
          setIsEditMode(false);
          form.resetFields();
          closeModal();
        }
      } catch (err) {
        if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
          message.error(t('object.message.editFail'));
        }
        setLoading(false);
        closeModal();
      }
    } else {
      try {
        setLoading(true);
        const resObj = await objectApi.editObjectTemplate(initialValues?.id, {
          ...formatStringToNumber(trimmedValues),
          object_link: initialValues?.object_link,
        });
        if (resObj.statusCode === STATUSCODE.SUCCESS) {
          message.success(t('object.message.editSuccess'));
          setLoading(false);
          setIsEditMode(false);
          form.resetFields();
          closeModal();
        }
      } catch (err) {
        if (err?.response?.data?.statusCode === STATUSCODE.BAD_REQUEST) {
          message.error(t('object.message.editFail'));
        }
        setLoading(false);
        closeModal();
      }
    }
  };
  const onFinish = async (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    if (!isEditMode) {
      createObject(trimmedValues);
    } else {
      editObject(trimmedValues);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const res = await objectApi.deleteObjectTemplate(id);
      if (res.statusCode === STATUSCODE.SUCCESS) {
        message.success('オブジェクトテンプレートの削除に成功しました。');
        closeModal();
      }
    } catch (error) {
      if (error.response.status === STATUSCODE.BAD_REQUEST) {
        message.error('オブジェクトテンプレートの削除に失敗しました。');
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      content: (
        <div className="flex-center flex-column modal-confirm-wrapper">
          <span className="jc-center">
            選択したテンプレートを削除しますか。削除したテンプレートはもとには戻せません。テンプレートに紐づいているオブジェクトも削除されます。
          </span>
        </div>
      ),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => handleDelete(initialValues?.id),
    });
  };
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 7 },
    },
    labelAlign: 'left',
  };
  useEffect(() => {
    if (isAddForm) {
      form.resetFields();
      setFileList([]);
    } else {
      form.setFieldsValue(listObjects);
    }
  }, [form, initialValues, isAddForm, listObjects]);

  useEffect(() => {
    setIsEditMode(Object.keys(initialValues)?.length);
  }, [initialValues]);
  const [listType, setListType] = useState();

  const typeListOpton = useMemo(
    () =>
      listType?.map((e) => ({
        label: e?.name,
        value: e?.id,
        ...e,
      })),
    [listType],
  );
  const fetchListType = async () => {
    try {
      const result = await objectApi.getListObjectType();
      setListType(result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const uploadModel = async () => {
    setLoadingModel(true);
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]?.originFileObj);
      const res = await uploadApi.uploadFile(formData);
      setFileLink(res?.data?.url);
      setLoadingModel(false);
    }
  };
  useEffect(() => {
    fetchListType();
    uploadModel();
  }, [fileList]);
  useEffect(() => {
    if (isModalOpen) {
      setFileLink('');
    }
  }, [fileList]);
  return (
    <ConfigProvider locale={localeValues}>
      <Card className="add-object-form">
        <Form
          name="tab2-form"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          colon={false}
          className="user-form"
          validateTrigger="onChange"
        >
          <div
            className={`${
              initialValues ? 'display-flex  jc-center' : 'display-flex '
            }`}
          >
            <Col span={12}>
              <TextField
                {...configTextField}
                label={t('dashboard.tab2.form.subjectTitle')}
                name="name"
                rules={[
                  {
                    validator: (rule, value, callback) =>
                      validateMax(rule, value, callback, 30, 'オブジェクト名'),
                  },
                ]}
              />
              <SelectField
                {...configTextField}
                labelCol={{ span: 12 }}
                label={t('object.form.objectType')}
                name="object_type_id"
                options={typeListOpton || []}
                rules={[
                  {
                    required: true,
                    message: t('object.message.requiredObjecType'),
                  },
                ]}
              />
              <ComUploadFile
                label={t('dashboard.tab2.form.data')}
                name="object_link"
                labelCol={{ span: 12 }}
                fileList={
                  (obj !== undefined &&
                    obj !== null && [
                      {
                        url: obj,
                        uid: -1,
                        name:
                          (obj && new URL(obj).pathname.split('/').pop()) || '',
                      },
                    ]) ||
                  fileList
                }
                setFileList={setFileList}
                isFile
                isObject
              />
            </Col>
            <Col span={12} className="pl-10 pb-10">
              <div className="display-flex">
                <div className="label-model pl-20 pr-20">プレビュー</div>
                {(fileLink || initialValues?.object_link) && (
                  <Model3d
                    dataPosition={dataPosition}
                    dataRotation={dataRotation}
                    label={t('dashboard.tab2.form.data')}
                    labelCol={{ span: 12 }}
                    modal3DFile={fileLink || initialValues?.object_link}
                    loadingModel={loadingModel}
                  />
                )}
              </div>
            </Col>
          </div>
          <>
            <Col span={24}>
              <div className="label-model pb-20">初期座標設定</div>
              <ComTextFieldGroup
                isObject
                label={
                  <Space direction="vertical">
                    <Text>座標</Text>
                  </Space>
                }
                name="position"
                rules={[
                  {
                    validator: (rule, value, callback) =>
                      validateFloat(
                        rule,
                        value,
                        callback,
                        t('fab.form.playerLandScape'),
                      ),
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <ComTextFieldGroup
                isObject
                label={
                  <Space direction="vertical">
                    <Text>{t('fab.form.rotation')}</Text>
                  </Space>
                }
                name="rotation"
                rules={[
                  {
                    validator: (rule, value, callback) =>
                      validateFloat(
                        rule,
                        value,
                        callback,
                        t('fab.form.rotation'),
                      ),
                  },
                ]}
              />
            </Col>
          </>
          <div className="full-width button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              {isEditMode ? '変更' : '追加'}
            </ComButton>
            {isEditMode !== 0 && (
              <ComButton
                onClick={() => confirmDelete()}
                className="save-btn btn-delete"
              >
                削除
              </ComButton>
            )}
          </div>
        </Form>
      </Card>
    </ConfigProvider>
  );
}
ObjectsForm.propTypes = {
  closeModal: PropTypes.func,
  isAddForm: PropTypes.bool,
  initialValues: PropTypes.any,
  isModalOpen: PropTypes.bool,
};
export default ObjectsForm;
